import React, {useEffect, useState} from 'react';
import { tableNamePaymentConstants, apiConstants } from '../../../constants/Common/apiConstants';
import apiCall from '../../../utils/apiFunction/apiCall';

const ScrollingText = () => {

  const [notification, setNotification] = useState('')

  const fetchBankingTeamNotification = async () => {
    const params = {
      tablename: tableNamePaymentConstants.BANKING_TEAM_NOTIFICATION,
      attributes: ["id", "issue_text", "display"],
      whereQuery: {
        display: true
      },
      limit: 1,
      order: [['id', 'DESC']]
    };
    try {
      const response = await apiCall.post(apiConstants?.findData, params);
      const notificationResponseList = response?.data?.response || [];

      setNotification(notificationResponseList.length === 0 ? '' : notificationResponseList[0].issue_text);
    } catch (error) {
      console.error("ERROR ::::::::::::::", error);
    }
  };

  useEffect(() => {
    fetchBankingTeamNotification()
  }, [])

  if(!notification) return <></>

  return (
    <div className="p-2 rounded-1 d-flex align-items-center" style={{backgroundColor: "#D3D3D3",fontSize: '18px'}}>
      <marquee direction='right' scrollamount="10">
            {notification}
      </marquee>
    </div>
  );
};

export default ScrollingText;
