import React, { useEffect, useState } from "react"
import Dropzone from "react-dropzone"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

import downloadIcon from "../../../Images/downloadIcon.svg"
import deleteIcon from "../../../Images/delete.svg"
import uploadIcon from "../../../Images/upload-icon.svg"
import SystemXLoader from "../../../component/common/Loader/SystemXLoader"
import {
  apiConstants,
  errMessage,
  singleAccountVerificationAPIMessage,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants"
import { ROUTE_PATH } from "../../../constants/Routes"
import "../../../styles/layout/common.scss"
import apiCall from "../../../utils/apiFunction/apiCall"
import uploadPresigned from "../../../utils/presignedUpload"
import "./VerifyAccountValidation.scss"
import { commonVariables } from "../../../constants/Common/commonConstants"
import moment from "moment/moment"

const VerifyAccount = () => {
  const loginState = useSelector((state) => state.login)
  const [systemOptions, setSystemOptions] = useState([])
  const [uploadPercent, setUploadPercent] = useState(0)
  const [File, setFile] = useState([])
  const [FileKey, setFileKey] = useState("")
  const [loading, setLoading] = useState(false)
  const [system, setSystem] = useState("")

  const onDrop = (file) => {
    if (file?.length > 0) {
      if (
        file?.[0]?.type == "text/csv" ||
        file?.[0]?.type == ".csv" ||
        file?.[0]?.type == "text/csv" ||
        file?.[0]?.type == ""
      ) {
        getPresigned(file)
      } else {
        toast.error(errMessage?.invalidFile, { toastId: "invalid file" })
      }
    }
  }
  const navigateToStatus = () => {
    navigate(ROUTE_PATH?.ACCOUNT_VALIDATION_STATUS)
  }
  const uploadLoder = (data) => {
    setUploadPercent(data)
  }
  const onSubmit = () => {
    if (FileKey && system) {
      let apiBody = {
        user_code: loginState?.decodedData?.user_code || "",
        user_name: loginState?.decodedData?.user_name || "",
        source_system: system,
      }
      setLoading(true)
      apiCall
        .post(`${apiConstants.apipaymentAccountValidation}?key=${FileKey}`, apiBody)
        .then((res) => {
          if (res?.data?.status === 200) {
            clearData()
            toast.success(
              res?.data?.message || singleAccountVerificationAPIMessage?.itemsProcessed,
              {
                toastId: toastIds?.FileUploadSuccess,
              },
            )
          } else if (res?.data?.status === 404) {
            toast.error(res?.data?.message, {
              toastId: toastIds?.FileUploadSuccess,
            })
            clearData()
          } else {
            toast.error(res?.data?.message, {
              toastId: toastIds?.FileUploaderr,
            })
          }
        })
        .catch((er) => {
          toast.error(er?.message, { toastId: toastIds?.FileUploaderr })
        })
        .finally(() => {
          setLoading(false)
        })
    } else {
      toast.error(errMessage?.uploadFileAndSelectValue, {
        toastId: toastIds?.FileUploaderr,
      })
    }
  }

  const downloadSampleFile = async () => {
    const key = "Payment/upload/Bulk_Sample_file/BULK_VALIDATION_SAMPLE_FILE.csv"

    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response?.status === 200) {
          const link = document.createElement("a")
          link.href = response?.data
          link.click()
          toast.success(commonVariables?.FileDownloadSuccess, {
            toastId: toastIds?.FileDownloadSucess,
          })
        }
      })
      .catch((error) => {
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        })
      })
  }

  const getSystemList = () => {
    setLoading(true)
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.System,
      })
      .then((res) => {
        let tempData = [...res?.data?.response]
        tempData = tempData.map((item) => {
          return {
            value: item?.system_code,
            label: item?.system_name,
          }
        })
        setSystemOptions([...tempData])
      })
      .catch((err) => {
        setSystemOptions([])
      })
      .finally(() => {
        setLoading(false)
      })
  }
  useEffect(() => {
    getSystemList()
  }, [])

  const getPresigned = (value) => {
    setLoading(true)
    const filename = `${moment().valueOf()}_${value?.[0]?.name}`;
    apiCall
      .get(
        `${apiConstants.apicommonUtilityPresignedS3Upload}?key=${moment()
          .format("YYYY/MM/DD")
          .toString()}/payment/upload/account-validation-bulk/${filename}`,
      )
      .then((res) => {
        if (res.status == 200) {
          let result = res?.data
          if (result) {
            uploadPresigned(value[0], result, uploadLoder)
              .then((res) => {
                if (res.status == 200) {
                  toast.success(singleAccountVerificationAPIMessage?.fileUploaded, {
                    toastId: "uploaduploaded",
                  })
                  setFile(value)
                  setFileKey(
                    `${moment()
                      .format("YYYY/MM/DD")
                      .toString()}/payment/upload/account-validation-bulk/${filename}`,
                  )
                }
              })
              .catch((e) => {
                setFile([])
                setFileKey("")
                toast.error(e?.message || errMessage?.fileUploadFailed, {
                  toastId: "uploaderror",
                })
              })
          }
        }
      })
      .catch((er) => {
        setFileKey("")
        setFile([])
        toast.error(er?.message || errMessage?.fileUploadFailed, {
          toastId: "uploaderror",
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const clearData = () => {
    setFileKey()
    setFile([])
    setSystem("")
  }

  const navigate = useNavigate()

  return (
    <>
      {loading ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <div className="ml-3 mt-4 mr-3">
          <div>
            <div className="d-flex justify-content-between ">
              <div className="mt-1">
                <label
                  className="common-header cursor-pointer pt-2"
                  onClick={() => navigateToStatus()}>
                  Account Validation &gt; &gt;
                  <span style={{ color: "black" }}>Verify Account Bulk </span>
                </label>
              </div>
              <div className="d-flex flex-wrap gap-1">
                <button
                  className="verifyAccountButtons_submitBtn"
                  onClick={() => navigateToStatus()}>
                  View Status
                </button>
                <button
                  className="verifyAccountButtons_submitBtn ml-3"
                  onClick={() => navigate(ROUTE_PATH?.SINGLE_VERIFY)}>
                  Single Verify
                </button>
              </div>
            </div>
          </div>
          <div className="manage-header pl-3 rounded-top mt-3 d-flex align-items-center">
            <label className="sub-header">Bulk Verify</label>
          </div>
          <div className="ml-3 rounded shadow-sm pb-5">
            <div className="row mt-4 ">
              <div className="col-md-6 ps-4 mb-2">
                <label className="manage-title">System</label>
                {/* <Dropdown
              placeholder="Please Select value"
              options={selectoptions}
              className="account-verfication-dropdown"
            /> */}
                <select
                  name="source_system"
                  onChange={(e) => setSystem(e.target.value)}
                  value={system}
                  className="form-select selectInput">
                  <option key="Please Select a Value" hidden value>
                    Please Select a Value
                  </option>
                  {systemOptions &&
                    systemOptions.map((value) => {
                      return <option value={value.value}>{value.label}</option>
                    })}
                </select>
              </div>
            </div>
            <div className="d-flex flex-wrap gap-1 justify-content-between align-items-center verifyAccount_UploadFile mb-2 ">
              <label className="manage-subtitle ps-3">Upload File</label>
              <button
                type="button"
                onClick={() => downloadSampleFile()}
                className="verifyAccountButtons_downloadBtn">
                <img src={downloadIcon} alt="downloadIcon" />
                Download Sample File
              </button>
            </div>
            <div className="row mb-4 pb-3">
              <div className="col-md-6 dropzone-manage d-flex justify-content-center align-items-center flex-wrap ms-4">
                {uploadPercent > 0 && uploadPercent < 100 ? (
                  `${uploadPercent}% Uploaded`
                ) : File?.[0]?.name ? (
                  <>
                    <p className="mt-2">{File?.[0]?.name}</p>
                    <img
                      src={deleteIcon}
                      onClick={(e) => {
                        setFile([])
                        setFileKey("")
                      }}
                      className="mb-2 cursor-pointer"
                      alt="delete icon"></img>
                  </>
                ) : (
                  <Dropzone acceptedFiles={[".csv, text/csv"]} onDrop={onDrop} multiple={false}>
                    {({ getRootProps, getInputProps }) => (
                      <div className="manage-dropzone">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <div className="d-flex flex-wrap gap-2">
                            <img
                              src={uploadIcon}
                              className="verifyDropZone_DropzoneWrapper_UploadWrapper_uploadIcon mb-2"
                              alt="upload icon"></img>
                            <p className="verifyDropZone_DropzoneWrapper_UploadWrapper_dragTxt verifyDropZone_DropzoneWrapper_label mt-2">
                              <b>
                                Drag your File here Or &nbsp;
                                <span className="verifyDropZone_DropzoneWrapper_UploadWrapper_browseTxt">
                                  Browse
                                </span>
                              </b>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                )}
              </div>
            </div>
            <div className="d-flex gap-4  ms-4 mb-5 pb-4">
              {system && File?.length != 0 ? (
                <button className="verifyAccountButtons_cancelBtn" onClick={() => clearData()}>
                  Cancel
                </button>
              ) : (
                ""
              )}
              <button
                className="verifyAccountButtons_submitBtn"
                onClick={() => {
                  onSubmit()
                }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default VerifyAccount
