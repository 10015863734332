import React, { useEffect, useState } from "react";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { toastIds } from "../../../constants/Common/apiConstants";
import { errorMessages } from "../../../constants/Common/errorConstants";
import moment from "moment";
import { formatDate, buildQueryParamsString } from "../../../utils/utils";
import PaymentHeader from "../../../component/reports/PaymentDashboard/PaymentHeader";
import CaseStatusWiseBarChart from "../../../component/reports/PaymentDashboard/CaseStatusWiseBarChart";
import ApiBasedBarChart from "../../../component/reports/PaymentDashboard/ApiBasedBarChart.";

const PaymentDashboardReport = () => {
  
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({})

  const onSubmit = async (data) => {

    if (moment(data["ToDate"], 'DD-MM-YYYY') < (moment(data["FromDate"], 'DD-MM-YYYY'))) {
      console.log("condition is called");
      toast.error(errorMessages.toDateMustBeGreaterThanToDate, {
        toastId: toastIds?.DataError,
      });
      return;
    }

    const queryString = buildQueryParamsString(data)

    await fetchPaymentReportData(queryString);
  };

  const fetchPaymentReportData = async (queryString) => {
    setLoading(true);

    let jsonResponse;

    try {
      const res = await fetch(
        `http://localhost:10018/reports/DashbordReport/DashboardPaymentReport${queryString}`
      );
      
      if (!res.ok) {
        throw Error;
      }

      jsonResponse = await res.json();

      setPaymentData(jsonResponse.response || {})

    } catch (error) {

      console.log(error);
      setPaymentData({})
      if(jsonResponse?.message !== 'No Data Found') {

        toast.error(jsonResponse?.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
    } finally {
        setLoading(false);
    }
  };

  
  useEffect(() => {

    const data = {
      FromDate: formatDate(new Date()),
      ToDate: formatDate(new Date()),
      sourceSystem: 'All',
    }

    const queryString = buildQueryParamsString(data)

    fetchPaymentReportData(queryString);
  }, []);

  const DisplayChart = () => {
    if (loading) {
      return (
        <div>
          <SystemXLoader />
        </div>
      );
    }

    if (Object.keys(paymentData).length === 0) {
      return <div className="mt-5 text-center h-100 d-flex align-items-center">No data found</div>;
    }

    return (
      <div
        className="flex-grow-1 d-grid mt-3"
        style={{
          gridTemplateColumns: "1fr 1fr 1fr",
          gridTemplateRows: "1fr",
          maxWidth: '1500px',
          gap: "1rem",
        }}
      >
        <div className="border shadow-sm rounded p-2">
          <CaseStatusWiseBarChart caseStatus={paymentData.caseStatus}/>
        </div>
        <>
        {paymentData.apiFlagBased.map((data, i) => {
          return (
            <div className="border shadow-sm rounded p-2" key={i}>
              <ApiBasedBarChart apiFlagBasedData={data} title={data.api_based_flag === 1 ? 'Api based' : 'File based'} />
            </div>
          )
        })}
        </>
      </div>
    );
  };

  return (
    <div>
      <div >
        <PaymentHeader onSubmit={onSubmit} loading={loading}/>
        
      </div>
      <div className="px-4 d-flex justify-content-center align-items-center" style={{minHeight: '625px'}}>
          <DisplayChart />
      </div>
    </div>
  );
};

export default PaymentDashboardReport;
