import React, { useEffect, useState } from "react";
import EnachChart from "../../../component/reports/EnachDashboard/EnachBarChart";
import EnachPieChart from "../../../component/reports/EnachDashboard/EnachPieChart";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { toastIds } from "../../../constants/Common/apiConstants";
import { errorMessages } from "../../../constants/Common/errorConstants";
import moment from "moment";
import EnachHeader from "../../../component/reports/EnachDashboard/EnachHeader";
import RegularVsSwap from "../../../component/reports/EnachDashboard/RegularVsSwap";
import { formatDate, buildQueryParamsString } from "../../../utils/utils";

const EnachDashboardReport = () => {
  
  const [loading, setLoading] = useState(false);
  const [enachData, setEnachData] = useState([]);
  const [grandTotals, setGrandTotals] = useState({});

  const onSubmit = async (data) => {

    if (moment(data["ToDate"], 'DD-MM-YYYY') < (moment(data["FromDate"], 'DD-MM-YYYY'))) {
      console.log("condition is called");
      toast.error(errorMessages.toDateMustBeGreaterThanToDate, {
        toastId: toastIds?.DataError,
      });
      setEnachData([]);
      setGrandTotals({});
      return;
    }

    const queryString = buildQueryParamsString(data)

    await fetchEnachReportData(queryString);
  };

  const fetchEnachReportData = async (queryString) => {
    setLoading(true);

    let jsonResponse;

    try {
      const res = await fetch(
        `https://apps72.tatacapital.com/reports/DashbordReport/DashbordEnachAdoptionReport${queryString}`
      );
      
      if (!res.ok) {
        throw Error;
      }

      jsonResponse = await res.json();

      setEnachData(jsonResponse.response.groupedResult);
      setGrandTotals(jsonResponse.response.grandTotals);

    } catch (error) {
      setEnachData([]);
      setGrandTotals({});
      console.log(error);
      if(jsonResponse?.message !== 'No Data Found') {

        toast.error(jsonResponse?.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
    } finally {
        setLoading(false);
    }
  };

  
  useEffect(() => {

    const data = {
      FromDate: formatDate(new Date()),
      ToDate: formatDate(new Date()),
      vendorType: 'All',
    }

    const queryString = buildQueryParamsString(data)

    fetchEnachReportData(queryString);
  }, []);

  const DisplayChart = () => {
    if (loading) {
      return (
        <div>
          <SystemXLoader />
        </div>
      );
    }

    if (!enachData.length) {
      return <div className="mt-5 text-center h-100 d-flex align-items-center">No data found</div>;
    }

    return (
      <div
        className="flex-grow-1 d-grid mt-3"
        style={{
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr",
          maxWidth: '1500px',
          gap: "1rem",
        }}
      >
        <div className="border shadow-sm rounded p-2">
          <EnachChart grandTotals={grandTotals} authModeData={enachData} />
        </div>
        <div className="border shadow-sm rounded p-2">
          <EnachPieChart data={enachData} />
        </div>
        {/* <div style={{ gridColumn: 'span 2' }} className="w-100 mt-3 border shadow-sm rounded p-2">
          <RegularVsSwap data={grandTotals}/>
        </div> */}
      </div>
    );
  };

  return (
    <div>
      <div >
        <EnachHeader onSubmit={onSubmit} loading={loading}/>
        
      </div>
      <div className="px-4 d-flex justify-content-center align-items-center" style={{minHeight: '625px'}}>
          <DisplayChart />
      </div>
    </div>
  );
};

export default EnachDashboardReport;
