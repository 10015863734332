import React from 'react';
import ReactECharts from 'echarts-for-react';

const PennyDropPieChart = ({ data }) => {
  // Calculate totals for KARZA, CASHFREE, and null
  const karzaTotal = data.reduce((acc, item) => {
    if (item.api_client_name === 'KARZA_API') {
      return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
    }
    return acc;
  }, 0);

  const cashfreeTotal = data.reduce((acc, item) => {
    if (item.api_client_name === 'CASHFREE_API') {
      return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
    }
    return acc;
  }, 0);

  // const nullTotal = data.reduce((acc, item) => {
  //   if (item.api_client_name === null) {
  //     return acc + (item["Total Success (S)"] || 0) + (item["Total Failed (J)"] || 0) + (item.Blank || 0);
  //   }
  //   return acc;
  // }, 0);

  const totalRegistered = karzaTotal + cashfreeTotal 
  // + nullTotal;

  // Total counts summary for the bottom right
  const totalCounts = [
    { name: 'KARZA', total: karzaTotal, color: '#A9A9B0' },
    { name: 'CASHFREE', total: cashfreeTotal, color: '#E27429' },
    // { name: 'Null', total: nullTotal, color: '#FFCC00' },
  ];

  const chartOptions = {
    title: {
      text: "Vendor Count",
      left: "left",
      // top: 1, // Adjusted top value for spacing
      textStyle: {
        color: "#36454F",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: 'item',
      formatter: (params) => {
        const { name, value } = params;
        const percentage = ((value / totalRegistered) * 100).toFixed(2);
        return `${name}: ${value} (${percentage}%)`;
      },
    },
    legend: {
      orient: "horizontal",
      left: "left",
      top: 40, // Adjusted top value for spacing below title
      itemGap: 10,
      itemWidth: 20,
      itemHeight: 14,
      padding: [0, 0, 0, 10], // Adds padding to position the legend better
      textStyle: {
        color: "#36454F",
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    series: [
      {
        name: 'Data',
        type: 'pie',
        center: ['50%', '54%'],
        data: [
          { value: karzaTotal, name: 'KARZA' },
          { value: cashfreeTotal, name: 'CASHFREE' },
          // { value: nullTotal, name: 'Null' },
        ],
        itemStyle: {
          normal: {
            color: (params) => {
              const colors = ['#A9A9B0', '#E27429' ]; //'#FFCC00' Custom colors for KARZA, CASHFREE, and Null
              return colors[params.dataIndex];
            },
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
    color: ['#5470c6', '#91cc75', '#FFCC00'],
    animation: true,
    animationDuration: 1000,
    animationEasing: 'cubicInOut',
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <ReactECharts option={chartOptions} style={{ height: '515px', width: '100%' }} />
      </div>
      <div
        style={{
          padding: '10px',
          backgroundColor: 'inherit',
          borderTop: '1px solid #ddd',
          display: 'flex',
          flexDirection: 'column',
        }}
        className='px-4 py-2'
      >
        <div style={{ color: '#36454F', fontSize: '16px', fontWeight: '600' }} className='mb-2'>
          Total Counts:
        </div>
        <div className='d-flex gap-2 flex-wrap'>

        {totalCounts.map((item, index) => (
          <div
          key={index}
          style={{
            marginBottom: '5px',
            display: 'flex',
            alignItems: 'center',
            fontSize: '14px'
          }}
          className='mr-2'
          >
            <div
              style={{
                width: '10px',
                height: '10px',
                backgroundColor: item.color,
                display: 'inline-block',
                marginRight: '5px',
                borderRadius: '50%',
              }}
              ></div>
            <span
              style={{ fontWeight: '500', color: '#808080' }}
              >
              {item.name}:
            </span>
            <span style={{ color: '#000000', fontWeight: '700', marginLeft: '5px' }}>
              {item.total}
            </span>
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default PennyDropPieChart;
