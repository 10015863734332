import moment from "moment"

export const OneWeekLaterDate=()=>{
    return moment().add(1, 'week').format('YYYY-MM-DD hh:mm:ss');
}

export const formatDate = (date) => {
    const formattedDate = moment(date).format("DD-MM-YYYY");
    return formattedDate;
};

export function buildQueryParamsString(paramsObj) {
    const queryParams = Object.entries(paramsObj).reduce((acc, [key, value]) => {
      if (value === "All") return acc;
  
      const formattedValue = value instanceof Date ? formatDate(value) : value;
  
      acc.push(`${key}=${formattedValue}`);
      return acc;
    }, []);
  
    return queryParams.length ? `?${queryParams.join("&")}` : "";
  }

// function to check if object values are null / 0
export const isEmpty = (obj) => {
    const flag = Object.values(obj).every((count) => count === 0);
    return flag;
};

export const isToDateBeforeFromDate = (fromDate, toDate) => {
    return moment(toDate).isBefore(moment(fromDate))
}

export const isAuthModeActive = (authModes, authModeName) => {
  const mode = authModes.find((mode) => mode.auth_mode === authModeName);
  return mode ? mode.is_active === 1 : false;
}

export const getClassName = (col, colIndex, row, colName, colValue) => {
  return col.id === colName && colIndex === 14 && row[colName] === colValue
    ? 'customFailedErrorColor'
    : '';
};