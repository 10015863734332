import React, { useEffect, useState } from "react";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { toastIds } from "../../../constants/Common/apiConstants";
import { errorMessages } from "../../../constants/Common/errorConstants";
import moment from "moment";
import { formatDate, buildQueryParamsString } from "../../../utils/utils";
import DTHeader from "../../../component/reports/DTDashboard/DTHeader";
import PresentationRepresentationBarChart from "../../../component/reports/DTDashboard/PresentRepresentBarChart";
import MergeDemergeBarChart from "../../../component/reports/DTDashboard/MergeDemergeBarChart";

const DTDashboardReport = () => {
  
  const [loading, setLoading] = useState(false);
  const [DTData, setDTData] = useState({});

  const onSubmit = async (data) => {
    console.log("submit is pressed--->", data)

    if (moment(data["ToDate"], 'DD-MM-YYYY') < (moment(data["FromDate"], 'DD-MM-YYYY'))) {
      toast.error(errorMessages.toDateMustBeGreaterThanToDate, {
        toastId: toastIds?.DataError,
      });
      return;
    }

    const queryString = buildQueryParamsString(data)

    await fetchDTReportData(queryString);
  };

  const fetchDTReportData = async (queryString) => {
    setLoading(true);

    let jsonResponse;

    try {
      const res = await fetch(
        `http://localhost:10018/reports/DashbordReport/DashboardDTReport${queryString}`
      );
      
      if (!res.ok) {
        throw Error;
      }

      jsonResponse = await res.json();
      console.log(jsonResponse)
      setDTData(jsonResponse.response || {})

    } catch (error) {
      console.log(error);
      setDTData({})
      if(jsonResponse?.message !== 'No Data Found') {
        toast.error(jsonResponse?.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
    } finally {
        setLoading(false);
    }
  };

  useEffect(() => {

    const data = {
      FromDate: formatDate(new Date()),
      ToDate: formatDate(new Date()),
      sourceSystem: 'All',
      bankName: 'All',
    }

    const queryString = buildQueryParamsString(data)

    fetchDTReportData(queryString);
  }, []);

  const DisplayChart = () => {
    if (loading) {
      return (
        <div>
          <SystemXLoader />
        </div>
      );
    }

    if (Object.keys(DTData).length === 0) {
      return <div className="mt-5 text-center h-100 d-flex align-items-center">No data found</div>;
    }

    return (
      <div
        className="flex-grow-1 d-grid mt-3"
        style={{
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr",
          maxWidth: '1500px',
          gap: "1rem",
        }}
      >
        <div className="border shadow-sm rounded p-2">
          <PresentationRepresentationBarChart presentRepresentCount={DTData.dtData} />
        </div>
        <div className="border shadow-sm rounded p-2">
          <MergeDemergeBarChart mergeDemergeCount={DTData.dtData} />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div >
        <DTHeader onSubmit={onSubmit} loading={loading}/>
        
      </div>
      <div className="px-4 d-flex justify-content-center align-items-center" style={{minHeight: '625px'}}>
          <DisplayChart />
      </div>
    </div>
  );
};

export default DTDashboardReport;
