import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../../component/common/Button/button";
import CustomTable from "../../../component/customtable/CustomTable";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import "../../enach/Enach.scss";
import apiCall from "../../../utils/apiFunction/apiCall";
import { toast } from "react-toastify";
import {
  apiConstants,
  errMessage,
  toastIds,
  reportConstants,

} from "../../../constants/Common/apiConstants";
import {
  componentRole
} from "../../../constants/Common/commonConstants";

const EachEnachAdoptionReport = () => {
  const location = useLocation();
  const [listdata, setListdata] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [loading, setLoading] = useState(false);
  const [downloadFlag, setdownloadFlag] = useState(false);
  const [id, setId] = useState("");
  const [count , setCount] = useState("")


  const queryParams = new URLSearchParams(location.search);

  const listApi = () => {
    const params = {
      file_name: queryParams.get("file_name"),
      from_date: queryParams.get("from_date"),
      report_type: queryParams.get("report_type"),
      to_date: queryParams.get("to_Date"),
      source_system: queryParams.get("source_system"),
      company_code: queryParams.get("company_code"),
      user_name: queryParams.get("user_name"),
      record_count: queryParams.get("count"),
      total_records: queryParams.get("total"),
      id: queryParams.get("id"),
    };
    setId(params.id);
    setCount(params.total_records);
    const totalChunk = Math.floor(params.total_records/50000) 
    let range = Array.from(
      { length: params.record_count },
      (_, index) => index + 1
    );

    const result = range.map((item ) => {

      return {
        id: params?.id,
        report_name: params?.file_name,
        start_date: params?.from_date,
        end_date: params?.to_date,
        source_system: params?.source_system,
        company_code: params?.company_code,
        count: item,
        total_count : item > totalChunk ? (params.total_records % 50000) : 50000,
        download_icon: addDownloadIcon(item, params),
      };
    });
    setListdata([...result]);
  };

  const addDownloadIcon = (index, params) => {
    return (
      <>
        <div className="d-flex justify-content-between">
          <Button
            role={componentRole.CANCEL}
            value="Download"
            type="submit"
            onClick={() => downloadEachChunkFile(index, params)}
          />
        </div>
      </>
    );
  };

  const downloadEachChunkFile = (index, params) => {
    setdownloadFlag(true);
    params["offset"] = 50000 * index;
    const api_url = reportConstants?.fetchEachEnachAdoptionReport;
    apiCall
      .post(api_url, params)
      .then((response) => {
        if (response?.status === 200) {
          if (response?.data?.status === 400)
            return toast.error(errMessage?.fileDownloadFailed, {
              toastId: toastIds?.DataError,
            });
          if (response?.data?.status === 500)
            return toast.error(errMessage?.InternalServerError, {
              toastId: toastIds?.InternalServerError,
            });
          if (response?.data?.status === 404)
            return toast.error(errMessage?.noDataFound, {
              toastId: toastIds?.DataError,
            });

          window.location.href = response?.data?.response;
        }
      })
      .catch((error) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      })
      .finally(() => {
        setdownloadFlag(false);
      });
  };


  useEffect(() => {
    listApi();
  }, []);

  return (
    <div>
      {downloadFlag && (
        <div className="d-flex justify-content-center d-flex align-items-center loaderDiv">
          <SystemXLoader />
        </div>
      )}

      <div>
        <div className="container">
          <div className="d-flex align-items-center justify-content-between mt-4 mb-3 mx-3">
            <div className="right-div-header">
              <div className="mt-3">
                <label className=" text-nowrap common-header">
                  Enach Adoption Reports - {id} - Total Count - {count}
                </label>
              </div>
            </div>
          </div>
          <div className="mandateTableWrapper">
            <CustomTable
              cols={[
                {
                  id: "report_name",
                  title: "Report Name",
                  classStyle: {},
                },
                {
                  id: "start_date",
                  title: "Start Date",
                  classStyle: {},
                },
                {
                  id: "end_date",
                  title: "End Date",
                  classStyle: {},
                },

                {
                  id: "source_system",
                  title: "Source system",
                  classStyle: {},
                },

                {
                  id: "company_code",
                  title: "Company Code",
                  classStyle: {},
                },

                {
                  id: "count",
                  title: "File Count",
                  classStyle: {},
                },
                {
                  
                    id: "total_count",
                    title: "Total Count",
                    classStyle: {},
                  
                },
                {
                  id: "download_icon",
                  title: "Download",
                  classStyle: {},
                },
              ]}
              data={listdata}           
              activePage={pageNumber}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EachEnachAdoptionReport;
