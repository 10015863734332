import React, { useEffect, useState } from "react";
import "./DtDemergeForm.scss";
import {
  WhereQuery,
  commonVariables,
  componentRole,
  dateFormats,
  S3FILEPATH,
} from "../../../constants/Common/commonConstants";
import { useDispatch, useSelector } from "react-redux";
import { showBar } from "../../../redux/reducer/sideBarStatus";
import FormLabel from "../../../component/common/formLabel/formLabel";
import uploadPresigned from "../../../utils/presignedUpload";
import apiCall from "../../../utils/apiFunction/apiCall";
import Button from "../../../component/common/Button/button";
import { toast } from "react-toastify";
import { Button as ButtonReact, Col, Row } from "react-bootstrap";
import { PresentationOptions } from "../../../constants/Common/commonConstants";
import Dropzone from "react-dropzone";
import { ReactComponent as FileIcon } from "../../../Images/file.svg";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import ReactDatePicker from "react-datepicker";
import {
  apiConstants,
  errMessage,
  successMsg,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import axios from "axios";
import moment from "moment";

const DebitTransactionDemergeForm = (props) => {
  const dispatch = useDispatch();
  const loginState = useSelector((state) => state.login);
  const [fileData, setFileData] = useState([]);
  const [reversalDate, setReversalDate] = useState();
  const [loading, setLoading] = useState(false);
  const [apiLoading, setApiLoading] = useState(false);
  const [fileValidation, setFileValidation] = useState({
    "text/plain": [".xlsx", ".xls", ".csv", ".txt"],
  });
  const [uploadPercent, setUploadPercent] = useState(0);
  const [systemOptions, setSystemOptions] = useState([]);
  const [mandateOptions, setMandateOptions] = useState([]);
  const [companyOptions, setCompanyOptions] = useState([]);
  const [sponsorOptions, setSponsorOptions] = useState([]);
  const [presentationType, setPresentationType] = useState([]);
  const [formValues, setFormValues] = useState({
    // source_system: {
    //   value: "",
    //   error: "",
    //   required: true,
    // },
    repayment_mode: {
      value: "",
      error: "",
      required: true,
    },
    company_code: {
      value: "",
      error: "",
      required: true,
    },
    bank_code: {
      value: "",
      error: "",
      required: true,
    },
    presentation_type: {
      value: "",
      error: "",
      required: true,
    },
  });
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [buttonClick, setButtonClick] = useState(false);

  const getMandateList = () => {
    setLoading(true);
    apiCall
      .post(apiConstants?.findData, {
        tablename: "mandateType",
      })
      .then((res) => {
        let tempData = [...res?.data?.response];
        tempData = tempData.map((item) => {
          return {
            value: item?.mandate_type,
            label: item?.mandate_name,
          };
        });
        setMandateOptions([...tempData]);
      })
      .catch((err) => {
        console.log("err", err);
        setMandateOptions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getCompanyList = () => {
    setLoading(true);
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.Company,
        whereQuery: WhereQuery,
      })
      .then((res) => {
        let tempData = [...res?.data?.response];
        tempData = tempData.map((item) => {
          return {
            value: item?.company_code,
            label: item?.company_name,
          };
        });
        setCompanyOptions([...tempData]);
      })
      .catch((err) => {
        setCompanyOptions([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getSponsorBank = (value) => {
    if (value) {
      setLoading(true);
      apiCall
        .post(apiConstants?.findData, {
          tablename: tableNamePaymentConstants?.SponsorBank,
          whereQuery: {
            mandate_type: value,
          },
        })
        .then((res) => {
          let tempData = [];
          if (res.data.status === 404) {
            toast.error(errMessage?.noDataForSelectedMandateType, {
              toastId: toastIds?.DataError,
            });
          } else {
            tempData = [...res?.data?.response];
            tempData = tempData.map((item) => {
              return {
                value: `${item.bank_code}--${item.bank_name}`,
                label: item?.bank_name,
              };
            });
          }
          setSponsorOptions([...tempData]);
        })
        .catch((err) => {
          setSponsorOptions([]);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSponsorOptions([]);
    }
  };
  const onChange = (e) => {
    let tempData = { ...formValues };
    tempData[e.target.name].value = e.target.value;
    e.target.value
      ? (tempData[e.target.name].error = false)
      : (tempData[e.target.name].error = true);
    setFormValues({ ...tempData });
  };
  const onFileUpload = (file) => {
    if (file.length > 0) {
      if (file[0].name.includes(" ")) {
        toast.error("Please remove spaces from filename", {
          toastId: toastIds?.FileUploaderr,
        });
      } else {
        setFileData(file);
        // getPresigned(file);
      }
    }
  };
  const uploadLoder = (data) => {
    setUploadPercent(data);
  };

  const getPresigned = (value, id) => {
    setLoading(true);
    const currentDate = moment().format("YYYY_MM_DD");
    const [year, month, day] = currentDate.split("_");
    apiCall
      .get(
        `${apiConstants.apicommonUtilityPresignedS3Upload
        }?key=${year}/${month}/${day}/${S3FILEPATH?.DT?.DT}/${S3FILEPATH?.COMMON?.UPLOADS
        }/${S3FILEPATH?.DT?.DEMERGE}/${S3FILEPATH?.DT?.RAW}/${reversalDate ? moment(reversalDate).format(dateFormats.dmyupd) : ""
        }/${id}/${value[0].name}`
      )
      .then(async (res) => {
        if (res.status === 200) {
          let result = res?.data;
          if (result) {
            setLoading(true);
            const cancelToken = axios.CancelToken;
            const source = cancelToken.source();
            setCancelTokenSource(source);
            uploadPresigned(value[0], result, uploadLoder, source)
              .then((res) => {
                if (res.status == 200) {
                  handleCancel(false);
                  dispatch(showBar(false));
                  toast.success(successMsg?.fileUploadedSuccessfully, {
                    toastId: toastIds?.FileUploadSuccess,
                  });
                }
              })
              .catch((e) => {
                toast.error(
                  res?.data?.message || errMessage?.fileUploadFailed,
                  { toastId: toastIds?.FileUploaderr }
                );
              })
              .finally(() => {
                setApiLoading(false);
                setLoading(false);
              });
          }
        }
      })
      .catch((er) => {
        setLoading(false);
        toast.error(er?.message || errMessage?.fileUploadFailed, {
          toastId: toastIds?.FileUploaderr,
        });
      })
      .finally(() => { });
  };

  const onSubmit = () => {
    if (fileData.length) {
      let userData = loginState?.decodedData;
      let tempFile = fileData[0].name.split(".");
      let tempData = { ...formValues };
      let splitData = tempData["bank_code"]?.value.split("--");
      let apiBody = {
        user_name: userData?.user_name || "",
        file_name: fileData[0]?.name || "",
        upload_status: "Processing",
        total_count: 0,
        succuss_count: 0,
        rejected_count: 0,
        pending_count: 0,
        bank_code: splitData[0] || "",
        mandate_type: tempData["repayment_mode"]?.value || "",
        company_code: tempData["company_code"]?.value || "",
        presentation_type: tempData["presentation_type"]?.value || "",
        upload_date_time:
          moment().format(dateFormats.dateFormatInMDYWithTimeAndAm) || "",
      };
      if (
        ((apiBody.bank_code == "HDFC" || apiBody.bank_code == "HDFC NUPAY") &&
          tempFile[tempFile.length - 1] == "txt") ||
        tempFile[tempFile.length - 1] == "TXT" ||
        (apiBody.bank_code == "TPSL" &&
          tempFile[tempFile.length - 1] == "txt") ||
        tempFile[tempFile.length - 1] == "TXT" ||
        (apiBody.bank_code == "TPSL" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.bank_code == "AXIS" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "BOB" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "ICICI" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "CUB" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "Union Bank of India" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "FBI" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "UTB" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "INDUSIND" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.bank_code == "ALLA" &&
          tempFile[tempFile.length - 1] == "txt") ||
        (apiBody.bank_code == "ALD" &&
          tempFile[tempFile.length - 1] == "txt") ||
        (apiBody.bank_code == "SBI" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.bank_code == "CORP" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.bank_code == "CORPBANK" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.bank_code == "KOTAK" &&
          tempFile[tempFile.length - 1] == "xls") ||
        (apiBody.bank_code == "OBC" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.bank_code == "TECHPROCESSSOLUTION" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.bank_code == "ICICI TPSL" &&
          tempFile[tempFile.length - 1] == "xlsx") ||
        (apiBody.mandate_type === "PDC" &&
          tempFile[tempFile.length - 1] === "csv") ||
        (apiBody.bank_code === "Consolidate Bank" &&
          tempFile[tempFile.length - 1] === "xls") ||
        (apiBody.bank_code === "229" &&
          tempFile[tempFile.length - 1] === "xlsx")
      ) {
        setApiLoading(true);
        apiCall
          .post(apiConstants?.createUser, {
            body: apiBody,
            tablename: "debitTransactionReversalResponse",
          })
          .then(async (res) => {
            if (res?.data?.status === 200) {
              if (res?.data?.response?.data?.id) {
                onUpdate(res.data.response.data);
              } else {
                toast.error(
                  res?.data?.message || errMessage?.somethingWentWrong,
                  {
                    toastId: toastIds?.FileUploaderr,
                  }
                );
              }
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
              setButtonClick(false);
            }
          })
          .catch((er) => {
            console.log("err", er);
            setApiLoading(false);
            let resp = er?.message || "";
            toast.error(resp, { toastId: toastIds?.FileUploaderr });
          })
          .finally(() => { });
      } else {
        toast.error(errMessage?.uploadFileCorrectCombination, {
          toastId: toastIds?.FileUploaderr,
        });
      }
    } else {
      toast.error(errMessage?.uploadFile, { toastId: toastIds?.FileUploaderr });
    }
  };
  const onUpdate = (data) => {
    if (fileData.length) {
      let apiBody = {
        batch_id: data.id || "",
      };
      setApiLoading(true);
      apiCall
        .post(apiConstants?.updateUser, {
          body: apiBody,
          tablename: "debitTransactionReversalResponse",
          whereQuery: {
            id: data.id || "",
          },
        })
        .then(async (res) => {
          if (res?.data?.status === 200) {
            if (res.data.response[0] === 1) {
              getPresigned(fileData, data.id);
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
            }
          } else {
            toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
              toastId: toastIds?.FileUploaderr,
            });
          }
        })
        .catch((er) => {
          console.log("err", er);
          setApiLoading(false);
          let resp = er?.message || "";
          toast.error(resp, { toastId: toastIds?.FileUploaderr });
        })
        .finally(() => { });
    } else {
      toast.error(errMessage?.uploadFile, { toastId: toastIds?.FileUploaderr });
    }
  };
  const submitForm = () => {
    if (reversalDate === undefined) {
      toast.error("Fill all required fields", { toastId: "uploaderror" });
    } else if (!validate()) {
      onSubmit();
    } else {
      toast.error(errMessage?.fillAllFields, { toastId: toastIds?.DataError });
    }
  };
  const validate = () => {
    let error = false;
    let tempForm = { ...formValues };
    Object.entries(tempForm).forEach((value) => {
      if (value[1].required) {
        if (value[1].value) {
          tempForm[value[0]].error = false;
        } else {
          tempForm[value[0]].error = true;
          error = true;
        }
      }
      return value;
    });
    setFormValues({ ...tempForm });
    return error;
  };

  const handleCancel = () => {
    setButtonClick(false);
    props?.onCancel();
    setUploadPercent(0);
    setSystemOptions([]);
    setMandateOptions([]);
    setCompanyOptions([]);
    setSponsorOptions([]);
    setReversalDate();
    setFileData([]);
    setLoading(false);
    setFormValues({
      repayment_mode: {
        value: "",
        error: "",
        required: true,
      },
      company_code: {
        value: "",
        error: "",
        required: true,
      },
      bank_code: {
        value: "",
        error: "",
        required: true,
      },
      presentation_type: {
        value: "",
        error: "",
        required: true,
      },
    });
    if (cancelTokenSource) {
      cancelTokenSource.cancel("Upload canceled by user.");
    }
  };
  useEffect(() => {
    getMandateList();
    getCompanyList();
  }, [formValues]);
  return (
    <>
      {loading || apiLoading || (uploadPercent > 0 && uploadPercent < 100) ? (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-end mr-3">
            <Button
              role={componentRole?.TRANSPARENT_CANCEL || ""}
              onClick={() => dispatch(showBar(false), handleCancel())}
            />
          </div>
          <div className="d-flex justify-content-start demergeLabel f-5 ms-4">
            <FormLabel value={commonVariables?.DEMERGE || ""} />
          </div>
          <div className="m-4">
            <div className="dynamicFormWrapper">
              <Row className="g-3 gx-5 mb-5">
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Repayment Mode <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="repayment_mode"
                      onChange={(e) => {
                        getSponsorBank(e.target.value);
                        onChange(e);
                      }}
                      value={formValues.repayment_mode.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {mandateOptions &&
                        mandateOptions.map((value) => {
                          return (
                            <option value={value.value}>{value.label}</option>
                          );
                        })}
                    </select>
                    {formValues.repayment_mode.error &&
                      formValues.repayment_mode.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Mandate Type is required
                        </p>
                      )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Sponsor Bank <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="bank_code"
                      onChange={(e) => onChange(e)}
                      onClick={() => {
                        if (!sponsorOptions?.length) {
                          if (!formValues.repayment_mode.value) {
                            toast.error("First select a mandate type ", {
                              toastId: "mandTypeselect",
                            });
                          } else {
                            toast.error(
                              "No data is present for selected mandate type",
                              { toastId: "noData" }
                            );
                          }
                        }
                      }}
                      value={formValues.bank_code.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {sponsorOptions &&
                        sponsorOptions.map((value) => {
                          return (
                            <option value={value.value}>{value.label}</option>
                          );
                        })}
                    </select>
                    {formValues.bank_code.error &&
                      formValues.bank_code.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Sponsor Bank is required
                        </p>
                      )}
                  </div>
                </Col>
                {/* )} */}
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Company Code <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="company_code"
                      onChange={(e) => onChange(e)}
                      value={formValues.company_code.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {companyOptions &&
                        companyOptions.map((value) => {
                          return (
                            <option value={value.value}>{value.label}</option>
                          );
                        })}
                    </select>
                    {formValues.company_code.error &&
                      formValues.company_code.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Company Code is required
                        </p>
                      )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Reversal Date <span className="textLabelRed">*</span>
                    </p>
                    <ReactDatePicker
                      className="customDatepicker"
                      selected={reversalDate}
                      dateFormat={"dd/MM/yyyy"}
                      onChange={(date) => {
                        setReversalDate(date);
                      }}
                      maxDate={new Date()}
                    />
                  </div>
                </Col>

                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>
                      Presentation Type <span className="textLabelRed">*</span>
                    </p>
                    <select
                      name="presentation_type"
                      onChange={(e) => onChange(e)}
                      value={formValues.presentation_type.value}
                      className="form-select selectInput"
                    >
                      <option key="Please Select a Value" hidden value>
                        Please Select a Value
                      </option>
                      {PresentationOptions &&
                        PresentationOptions.map((value) => {
                          return <option value={value.key}>{value.key}</option>;
                        })}
                    </select>
                    {formValues.presentation_type.error &&
                      formValues.presentation_type.required && (
                        <p className="textLabelRed m-0 mt-2">
                          Presentation Type is required
                        </p>
                      )}
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="inputDiv mt-1">
                    <p>Upload File</p>
                    <Dropzone
                      onDrop={onFileUpload}
                      multiple={false}
                      accept={{
                        ...fileValidation,
                      }}
                      disabled={uploadPercent > 0 && uploadPercent < 100}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div
                          {...getRootProps({
                            className:
                              "dropWrapper d-flex align-items-center justify-content-center",
                          })}
                        >
                          <input {...getInputProps()} />
                          <div className="d-flex justify-content-center flex-wrap">
                            <div className="d-flex gap-2 uploadContent">
                              {fileData[0]?.name && <FileIcon />}
                              {uploadPercent > 0 && uploadPercent < 100
                                ? `${uploadPercent}% Uploaded`
                                : fileData[0]?.name || (
                                  <div className="uploadComponentLabel">
                                    Upload File
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </Col>
                <div className="d-flex gap-4">
                  <ButtonReact
                    className="customButtonGreenInverted"
                    onClick={() => dispatch(showBar(false), handleCancel())}
                  >
                    Cancel
                  </ButtonReact>
                  <ButtonReact
                    className="customButtonGreen"
                    disabled={
                      (uploadPercent > 0 && uploadPercent < 100) || buttonClick
                    }
                    onClick={() => submitForm()}
                  >
                    Submit
                  </ButtonReact>
                </div>
              </Row>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DebitTransactionDemergeForm;
