import React from 'react';
import ReactECharts from 'echarts-for-react';

const PennyDropBarChart = ({ grandTotals }) => {
  // Extract grand total values
  const totalSuccess = grandTotals["Grand Total Success (S)"];
  const totalFailed = grandTotals["Grand Total Failed (J)"];
  const totalBlank = grandTotals["Grand Total Blank"];
  const totalRegistered = grandTotals['Grand Total Registered Cases'];

  // Data for the chart
  const categories = ['Total Success', 'Total Failed', 'Total Registered', 'Total Blank'];
  const seriesData = [totalSuccess, totalFailed, totalRegistered, totalBlank];

  // Calculate the total counts
  const totalCounts = [
    { name: 'Total Success', total: totalSuccess },
    { name: 'Total Failed', total: totalFailed },
    { name: 'Total Registered', total: totalRegistered },
    { name: 'Total Blank', total: totalBlank },
  ];

  // Chart options for ECharts
  const options = {
    title: {
      text: 'Grand Total Summary',
      left: 0,
      textStyle: {
        color: "#36454F",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        let tooltipText = `<div style="font-weight: bold;">${params[0].name}: ${params[0].value}</div>`;
        return tooltipText;
      },
      textStyle: {
        color: '#000', // Default text color
      },
      borderWidth: 1,
      borderColor: '#ccc', // Optional border color
      extraCssText: 'border-radius: 5px; padding: 10px;',
    },
    xAxis: {
      type: 'category',
      data: categories,
      axisLabel: {
        rotate: 0, // You can change the rotation if needed
      },
    },
    yAxis: {
      type: 'value',
      // name: 'Count',
    },
    series: [
      {
        name: 'Count',
        type: 'bar',
        data: seriesData,
        itemStyle: {
          color: '#5470c6', // Custom color
        },
        label: {
          show: true,
          position: 'top',
          color: '#304758', // Custom label color
          fontSize: 14,
        },
        barWidth: '55%',

      },
    ],
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <ReactECharts option={options} style={{ height: '500px', width: '100%' }} />
      </div>
      <div
        style={{
          padding: '10px',
          backgroundColor: 'inherit',
          borderTop: '1px solid #ddd',
        }}
        className='px-4 py-2'
      >
        <div className="d-flex flex-column">
          <div>
            <div style={{ color: "#36454F", fontSize: "16px", fontWeight: "600" }} className='mb-2'>
              Total Counts:
            </div>
            <div className='d-flex gap-2 flex-wrap'>
              {totalCounts.map((item, index) => (
                <div
                  key={index}
                  style={{
                    marginBottom: "5px",
                    fontSize: "14px"
                  }}
                  className="d-flex align-items-center justify-content-end mr-2" 
                >
                  <div
                    style={{
                      width: "10px",
                      height: "10px",
                      backgroundColor: '#5470c6', // Use the same color as the bars
                      display: "inline-block",
                      marginRight: "5px",
                      borderRadius: "50%",
                    }}
                  ></div>
                  <span
                    className="d-block me-1"
                    style={{ fontWeight: "500", color: "#808080" }}
                  >
                    {item.name}:
                  </span>
                  <span style={{ color: "#000000", fontWeight: "700" }}>
                    {item.total}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PennyDropBarChart;
