import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage } from "formik";
import FormLabel from "../../../../../component/common/formLabel/formLabel";
import { ROUTE_PATH } from "../../../../../constants/Routes";
import FormikSelect from "../../../../../component/common/formikSelect/formikSelect";
import Button from "../../../../../component/common/Button/button";
import "./createCheque.scss";
import apiCall from "../../../../../utils/apiFunction/apiCall"
import {
  apiConstants,
  FOMRS,
  isActiveDropdowns,
  STATUS, tableNamePaymentConstants, successMsg, toastIds
} from "../../../../../constants/Common/apiConstants";
import { useNavigate,useLocation } from "react-router-dom";
import { useSelector} from "react-redux"
import React, { useState, useEffect } from "react";
import { YupMessages } from "../../../../../constants/Common/commonConstants";
import { toast } from "react-toastify";

let validationSchema = Yup.object({
  accountNo: Yup.string()
  .required(YupMessages?.BankAccountNoRequired)
  .matches(
    /^(?!.*<script>).*$/,
    "Account Number cannot contain scripts or HTML tags"
  )
  .matches(
    /^(?!.*<\/?[a-z][\s\S]*>).*$/,
    "Account Number cannot contain scripts or HTML tags"
  )
  .matches(/^[^!@#$%^&*]+$/, "Account Number cannot contain symbols")
  .test(
    "no-spaces",
    "Account Number cannot be only spaces",
    (value) => value.trim() !== ""
  ),
  lotNo: Yup.string()
  .required(YupMessages?.LOT_NUMBER)
  .matches(
    /^(?!.*<script>).*$/,
    "Lot Number cannot contain scripts or HTML tags"
  )
  .matches(
    /^(?!.*<\/?[a-z][\s\S]*>).*$/,
    "Lot Number cannot contain scripts or HTML tags"
  )
  .matches(/^[^!@#$%^&*]+$/, "Lot Number cannot contain symbols")
  .test(
    "no-spaces",
    "Lot Number cannot be only spaces",
    (value) => value.trim() !== ""
  ),
  chequefrom: Yup.string()
  .required(YupMessages?.CHEQUE_FROM)
  .matches(
    /^(?!.*<script>).*$/,
    "Cheque From cannot contain scripts or HTML tags"
  )
  .matches(
    /^(?!.*<\/?[a-z][\s\S]*>).*$/,
    "Cheque From cannot contain scripts or HTML tags"
  )
  .matches(/^[^!@#$%^&*]+$/, "Cheque From cannot contain symbols")
  .test(
    "no-spaces",
    "Cheque From cannot be only spaces",
    (value) => value.trim() !== ""
  ),
  chequetill: Yup.string()
  .required(YupMessages?.CHEQUE_TILL)
  .matches(
    /^(?!.*<script>).*$/,
    "Cheque Till cannot contain scripts or HTML tags"
  )
  .matches(
    /^(?!.*<\/?[a-z][\s\S]*>).*$/,
    "Cheque Till cannot contain scripts or HTML tags"
  )
  .matches(/^[^!@#$%^&*]+$/, "Cheque Till cannot contain symbols")
  .test('is-greater', 'Cheque Till must be greater than Cheque From', function(value) {
    const { chequefrom } = this.parent;
    return !chequefrom || !value || value > chequefrom;
  }),
  company: Yup.string()
    .required(YupMessages?.CompanyNameRequired)
    .matches(
      /^(?!.*<script>).*$/,
      "Company name cannot contain scripts or HTML tags"
    )
    .matches(
      /^(?!.*<\/?[a-z][\s\S]*>).*$/,
      "Company name cannot contain scripts or HTML tags"
    )
    .matches(/^[^!@#$%^&*]+$/, "Company name cannot contain symbols")
    .test(
      "no-spaces",
      "company cannot be only spaces",
      (value) => value.trim() !== ""
    ),
    remark: Yup.string()
    .required(YupMessages?.REMARK)
    .matches(
      /^(?!.*<script>).*$/,
      "remark cannot contain scripts or HTML tags"
    )
    .matches(
      /^(?!.*<\/?[a-z][\s\S]*>).*$/,
      "remark cannot contain scripts or HTML tags"
    )
    .matches(/^[^!@#$%^&*]+$/, "remark cannot contain symbols")
    .test(
      "no-spaces",
      "remark cannot be only spaces",
      (value) => value.trim() !== ""
    ),
  instrumenttype: Yup.string().required(YupMessages?.isActiveRequired),
  isActive: Yup.string().required(YupMessages?.isActiveRequired),
});

const Createcheque = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const loginState = useSelector((state) => state.login)
  const formType = state?.type || FOMRS.ADD;
  const headingText = formType === FOMRS.ADD ? "Create ChequeMaster" : "Update ChequeMaster";

  const [formData, setFormData] = useState({
    id:"",
    accountNo: "",
    lotNo: "",
    chequefrom: "",
    chequetill: "",
    company: "",
    instrumenttype: "",
    remark:"",
    isActive: "",
  });

  const [loading, setLoading] = useState(false);

  const FormSubmit = (value) => {
    console.log("------------inside Submit",value);
    let userData = loginState?.decodedData
    let query = {
      body: {
        company_code: value.company ? value.company : '-',
        account_no:  value.accountNo ? value.accountNo : '-',
        lot_no:  value.lotNo ? value.lotNo : '-',
        cheque_from:  value.chequefrom ? value.chequefrom : '-',
        cheque_til: value.chequetill ? value.chequetill : '-',
        instrument_type:  value.instrumenttype ? value.instrumenttype : '-',
        remark: value.remark ? value.remark : '-',
        isActive:  value.isActive ? value.isActive : '-',
        createdBy: userData?.user_name || "",
      },
      tablename: 'cheque_master',
    };
    let url;
    if (state?.type === FOMRS.EDIT) {
      url = apiConstants?.updateUser;
      query.whereQuery = {
        id: state?.user_type?.id,
      };
      toast.success(successMsg?.CompanyUpdatedSuccessfully, toastIds?.FileUploadSuccess)
    } else {
      url = apiConstants?.createUser;
      toast.success(successMsg?.CompanyCreatedSuccessfully, toastIds?.FileUploadSuccess)
    }
    apiCall.post(url, query).then(async (res) => {
      if (res?.status === STATUS.SUCCESS) {
        navigate(ROUTE_PATH.CHEQUE_MAKER);
      }
    });
  };
  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      console.log("-------------->",state?.user_type?.id);
      setFormData({
        id: state?.user_type?.id,
        accountNo: state?.user_type?.account_no,
        lotNo: state?.user_type?.lot_no,
        chequefrom: state?.user_type?.cheque_from,
        chequetill: state?.user_type?.cheque_til,
        company: state?.user_type?.company_code,
        instrumenttype: state?.user_type?.instrument_type,
        remark:state?.user_type?.remark,
        isActive: state?.user_type?.isActive,
      });
    }
    console.log("--------->",formData);

  }, [loading]);

  return (
    <Formik
      initialValues={{
        id:formData?.id,
        accountNo: formData?.accountNo,
        lotNo: formData?.lotNo,
        chequefrom: formData?.chequefrom,
        chequetill: formData?.chequetill,
        company: formData?.company,
        instrumenttype: formData?.instrumenttype,
        remark:formData?.remark,
        isActive: formData?.isActive,
      }}
      validationSchema={validationSchema}
      enableReinitialize={true}
      onSubmit={(values) => {
        FormSubmit(values);
      }}
    >
      {({ values, setFieldValue }) => (
        <>
          <div id="createCompany">
            <div
              className="ml-3 blueFonts large_f"
              onClick={() => navigate(ROUTE_PATH.CHEQUE_MAKER)}
            >
              {`Cheque Master >> `}
              <span className="blackFonts">{headingText}</span>
            </div>
            <div className="main-div ml-3">
              <Form className=".labler-wrapper">
                <div id="RegularForm">
                  <div className="createroletext">
                    <p id="pText">{headingText}</p>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel value="accountNo" className="required" />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="accountNo"
                        id="accountNo"
                        placeholder="Account No"
                        // disabled={state?.type === FOMRS.EDIT ? true : false}
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="accountNo" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel value="LotNo" className="required" />
                      <Field
                        type="text"
                        className="InputField"
                        name="lotNo"
                        id="lotNo"
                        placeholder="Lot No"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="lotNo" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel
                        value="ChequeFrom"
                        className="required"
                      />
                      <Field
                        type="text"
                        className="InputField"
                        name="chequefrom"
                        id="chequefrom"
                        placeholder="Cheque From"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="chequefrom" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel
                        value="ChequeTill"
                        className="required"
                      />
                      <Field
                        type="text"
                        className="InputField"
                        name="chequetill"
                        id="chequetill"
                        placeholder="Cheque Till"
                        value={values.chequetill}
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="chequetill" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel value="Company" className="required" />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="company"
                        id="company"
                        placeholder="Company"
                        disabled={state?.type === FOMRS.EDIT ? true : false}
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="company" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
                      <FormLabel
                        value="Remark"
                        className="required"
                      />
                      <Field
                        type="text"
                        className="InputField"
                        name="remark"
                        id="remark"
                        placeholder="Remark"
                      ></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="remark" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="instrumentType" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="instrumenttype"
                          options={isActiveDropdowns}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="instrumenttype" />
                      </div>
                    </div>
                  </div>

                  <div className="row paddingTop ml-4 mr-4">
                    <div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
                      <div className="formState">
                        <FormLabel value="Is Active" className="required" />
                        <FormikSelect
                          label="Please select a value"
                          name="isActive"
                          options={isActiveDropdowns}
                        />
                      </div>
                      <div className="errorMessage">
                        <ErrorMessage name="isActive" />
                      </div>
                    </div>
                  </div>

                  <div className=" d-flex py-4">
                    <div className="cancel_btn">
                      <Button
												className='mr-1'
                        role="secondary"
                        value="Cancel"
                        type="button"
                        onClick={() => navigate(ROUTE_PATH?.CHEQUE_MAKER)}
                      />
                    </div>

                    <div className="submitButton">
                      <Button
												className='mx-1'
                        role="primary"
                        value="Submit"
                        type="submit"
                        onClick={() => { }}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

export default Createcheque;
