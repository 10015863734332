import React from 'react';
import ReactECharts from 'echarts-for-react';

const CaseStatusWiseBarChart = ({ caseStatus }) => {
  // Extract PresentRepresentCount values
  const regular = caseStatus["TotalRegular"];
  const refund = caseStatus["TotalRefund"];
  const hold = caseStatus["TotalHold"];
  const exception = caseStatus["TotalException"];

  // Data for the chart
  const categories = ['Total Regular', "Total Refund", "Total Hold", "Total Exception"];
  const seriesData = [regular, refund, hold, exception];

  // Chart options for ECharts
  const options = {
    title: {
      text: "Case status count summary",
      textStyle: {
        color: "#36454F",
        fontSize: 14,
        fontWeight: "bold",
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
      formatter: function (params) {
        const categoryName = params[0].name;
        const totalValue = params[0].value;
  
        // Category header in bold and in the format 'category: total'
        return `<div style="font-weight: bold; padding: 5px;">
                  ${categoryName}: ${totalValue}
                </div>`;
      },
      textStyle: {
        color: '#000', // Default text color
      },
      borderWidth: 1,
      borderColor: '#ccc', // Optional border color
      extraCssText: 'border-radius: 5px; padding: 10px;',
    },
    xAxis: {
      type: 'category',
      data: categories,
      axisLabel: {
        rotate: 0,
        interval: 0,
      },
    },
    yAxis: {
      type: 'value',
      title: {
        text: 'Total Count',
      },
    },
    series: [
      {
        name: 'Total Count',
        type: 'bar',
        data: seriesData,
        label: {
          show: true,
          position: 'top',
          color: '#000',
        },
        itemStyle: {
          color: '#5470c6',
        },
        barWidth: '70%',
      },
    ],
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
      <div style={{ flex: 1 }}>
        <ReactECharts option={options} style={{ height: '500px', width: '100%' }} />
      </div>
      <div style={{ padding: '10px', backgroundColor: 'inherit', borderTop: '1px solid #ddd' }}>
        <div className="d-flex flex-column">
          <div>
            <div style={{ color: '#36454F', fontSize: '16px', fontWeight: '600' }} className='mb-2'>
            Case status Counts:
            </div>
            <div className='d-flex gap-2 flex-wrap'>
              {categories.map((name, index) => (
                <div key={index} style={{ marginBottom: '5px', fontSize: '14px' }} className="d-flex align-items-center justify-content-end mr-2">
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      backgroundColor: options.series[0].itemStyle.color, // Use the same color as the bars
                      display: 'inline-block',
                      marginRight: '5px',
                      borderRadius: '50%',
                    }}
                  ></div>
                  <span style={{ fontWeight: '500', color: '#808080', marginRight: '5px' }}>
                    {name}:
                  </span>
                  <span style={{ color: '#000000', fontWeight: '700' }}>
                    {seriesData[index]}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStatusWiseBarChart;
