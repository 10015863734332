import React, { useEffect, useState } from "react";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import { toastIds } from "../../../constants/Common/apiConstants";
import { errorMessages } from "../../../constants/Common/errorConstants";
import moment from "moment";
import PennyDropBarChart from "../../../component/reports/PennyDropDashboard/PennyDropBarChart";
import PennyDropPieChart from "../../../component/reports/PennyDropDashboard/PennyDropPieChart";
import PennyDropHeader from "../../../component/reports/PennyDropDashboard/PennyDropHeader";
import VendorPerformanceMetrics from "../../../component/reports/PennyDropDashboard/VendorPerformanceMetrics";
import { formatDate, buildQueryParamsString, isEmpty, isToDateBeforeFromDate } from "../../../utils/utils";

const PennyDropDashboardReport = () => {
  const [loading, setLoading] = useState(false);
  const [pennyDropGrandCount, setPennyDropGrandCount] = useState([]);
  const [groupedCounts, setGroupedCounts] = useState({});

  const [vendorPerformance, setVendorPerformance] = useState([])

  const onSubmit = async (data) => {
    const queryString = buildQueryParamsString(data);

    if (isToDateBeforeFromDate(data['FromDate'], data['ToDate'])) {
      toast.error(errorMessages.toDateMustBeGreaterThanToDate, {
        toastId: toastIds?.DataError,
      });
      setPennyDropGrandCount({});
      return;
    }

    await fetchPennyDropData(queryString);
  };

  const fetchPennyDropData = async (queryString) => {
    setLoading(true);

    const url = `https://apps72.tatacapital.com/reports/DashbordReport/DashbordPennyDropReport${queryString}`;

    let jsonResponse;
    try {
      const res = await fetch(url);

      if (!res.ok) {
        throw Error;
      }

      jsonResponse = await res.json();
      console.log(jsonResponse);

      if (isEmpty(jsonResponse.response.grandTotals)) {
        setPennyDropGrandCount({});
        setGroupedCounts([]);
        setVendorPerformance([])
        return;
      }

      setPennyDropGrandCount(jsonResponse.response.grandTotals);
      setGroupedCounts(jsonResponse.response.groupedCounts);
      setVendorPerformance(jsonResponse.response.vendorPerformance);

    } catch (error) {

      setPennyDropGrandCount({});
      setGroupedCounts([]);
      setVendorPerformance([])

      console.log(error);
      
      if(jsonResponse?.message !== 'No Data Found') {

        toast.error(jsonResponse?.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {

    const queryArray = {
      FromDate: formatDate(new Date()),
      ToDate: formatDate(new Date()),
      sourceSystem: "All",
      caseStatus: "All",
    };

    const queryString = buildQueryParamsString(queryArray);

    fetchPennyDropData(queryString);
  }, []);

  const DisplayChart = () => {
    if (loading) {
      return (
        <div>
          <SystemXLoader />
        </div>
      );
    }

    if (isEmpty(pennyDropGrandCount)) {
      return <div className="mt-5 text-center">No data found</div>;
    }

    return (
      <div
        className="d-grid mt-3 flex-grow-1"
        style={{
          gridTemplateColumns: "1fr 1fr",
          gridTemplateRows: "1fr", //"1fr 0.3fr 0.3fr",
          maxWidth: '1500px',
          gap: "1rem",
        }}
      >
        <div className="p-2 border shadow-sm rounded">
          <PennyDropBarChart grandTotals={pennyDropGrandCount} />
        </div>
        <div className="p-2 border shadow-sm rounded">
          <PennyDropPieChart data={groupedCounts} />
        </div>

        {/* <VendorPerformanceMetrics vendorPerformance={vendorPerformance}/> */}
      </div>
    );
  };

  return (
    <div>
      <div>
        <PennyDropHeader loading={loading} onSubmit={onSubmit} />
      </div>
      <div
        className="px-4 d-flex justify-content-center align-items-center"
        style={{minHeight: '625px'}}
      >
        <DisplayChart />
      </div>
    </div>
  );
};

export default PennyDropDashboardReport;
