import React, { useState, useEffect } from "react";
import Button from "../../common/Button/button";
import FormLabel from "../../common/formLabel/formLabel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { number, object, string } from "yup";
import { toast } from "react-toastify";
import FormikSelect from "../../common/formikSelect/formikSelect";
import "./systemDetails.scss";
import { EnachStatusCode, YupMessages } from "../../../constants/Common/commonConstants";
import apiCall from "../../../utils/apiFunction/apiCall";
import { useDispatch, useSelector } from "react-redux";
import { systemDetails } from "../../../redux/reducer/SingleMandateReducer";
import {
  apiConstants,
  attributePaymentConstants,
  DtRepresentationTableOrder,
  errMessage,
  fetchBankCodeAttributes,
  FOMRS,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import { useLocation, useNavigate } from "react-router-dom";
import DropdownWithTooltip from "../../common/DropdownWithTooltip/DropdownWithTooltip";

function SystemDetails(props) {
  const { state } = useLocation();

  const formType = state?.type || FOMRS.ADD;

  const fetchDataById = (id) => {
    const params = {
      tablename: "manualMandate",
      whereQuery: { id: id },
      attributes: [
        "company_code",
        "product_type",
        "source_system",
        "bank_code",
        "loan_no",
        "web_top",
        "los_id",
        "frequency_code",
      ],
    };
    apiCall
      .post(apiConstants?.findData, params)
      .then((response) => {
        if (response.status === 200) {
          const result = response?.data?.response[0];
          setFormData({
            company_code: result?.company_code,
            product_type: result?.product_type,
            source_system: result?.source_system,
            bank_code: result?.bank_code,
            loan_number: result?.loan_no,
            web_top: result?.web_top,
            los_id: result?.los_id,
            frequency: result?.frequency_code,
          });
        }
      })
      .catch((error) => {
        toast.error("Internal Server Error", { toastId: "FetchError" });
      });
  };

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      fetchDataById(state?.id);
    }
  }, []);

  const dispatch = useDispatch();
  const { handleNext } = props;
  const [mandateOption, setMandateOption] = useState([]);
  const [sourceSystem, setSourceSystem] = useState([]);
  const [productType, setProductType] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [noBank, setNoBank] = useState([
  ]);

  const [companyOptions, setCompanyOptions] = useState([
    { key: "Please Select Company Code", value: "" },
  ]);
  const [bankCode, setBankCode] = useState([
    { key: "Please Select Bank Code", value: "" },
  ]);
  const [companyCode, setCompanyCode] = useState("");

  const updateNoBank = () => {
    setNoBank(
      [
        { key: "Please Select a Purpose", tooltipTitle: "Please Select a Purpose"},
      { key: "Not Banked", value: "Not Banked", tooltipTitle: "Not Banked" },
      { key: "New Customer", value: "New Customer", tooltipTitle: "New Customer" },
    ]
    );
  };

  const [formData, setFormData] = useState({
    manual_mandate: "",
    sponsor_bank: "",
    company_code: "",
    product_type: "",
    source_system: "",
    bank_code: "",
    loan_booking_system: "",
    loan_number: "",
    web_top: "",
    los_id: "",
    frequency: "",
    purpose: "",
    bank_upi_flag : "",
    product_upi_flag : "",
  });

  const onContinue = (systemValues) => {
    const {product_type} = systemValues
    const productDetails = productType.filter((item) => item?.value === product_type);
    const {upi_flag} = {...productDetails[0]}
    systemValues.product_upi_flag = upi_flag

    dispatch(systemDetails({ systemValues }));
    return handleNext();
  };


  const formRef = React.useRef(null);
  const validSchema = object({
    manual_mandate: string().required(YupMessages.SelectMandateType),
    company_code: string().required(YupMessages.SelectCompanyCode),
    product_type: string().required(YupMessages.SelectProductType),
    source_system: string().required(YupMessages.SelectSourceSystem),
    purpose: string().required(YupMessages.SelectPurposeType),
    loan_number: string().test('conditional-validation', YupMessages.EnterValidLoanNumber, function(value) {
      const { purpose } = this.parent; // Accessing the parent object
      if (purpose == undefined) {
        return value !== undefined && value !== '' ? true : this.createError({ message: YupMessages.EnterLoanNUmber });
      }
      else if(purpose === 'Not Banked'){
        return value !== undefined && value !== '' ? true : this.createError({ message: YupMessages.EnterLoanNUmber });
      }
      else if(purpose === 'Please Select a Purpose'){
        return value !== undefined && value !== '' ? true : this.createError({ message: YupMessages.EnterLoanNUmber });
      }
      return true; // If purpose is 'New Customer', loan_number validation is not required
    }),
    web_top: string().test('is-valid', YupMessages.EnterWebTop, (value) => /^[a-zA-Z0-9]+$/.test(value)).matches(/^(?!.*<script>).*$/, YupMessages?.EnterWebTop),
    los_id: string().test('is-valid', YupMessages.EnterLosId, (value) => /^[a-zA-Z0-9]+$/.test(value)).matches(/^(?!.*<script>).*$/, YupMessages?.EnterLosId),
    frequency: string().required(YupMessages.SelectFrequencyType),
  });

  const fetchMandateTypes = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: "mandateType",
        attributes: ["mandate_type", "mandate_name"],
      })
      .then((response) => {
        if (response.status === 200) {
          let result = response?.data?.response;
          result = result.map((item) => {
            const new_item = {
              key: item?.mandate_name,
              value: item?.mandate_type,
            };
            return new_item;
          });
          result.splice(0, 0, {
            key: " Please Select Mandate Type",
            value: "",
          });
          setMandateOption([...result]);
        }
      })
      .catch((error) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };

  const fetchBankCode = (companyCode) => {
    if (companyCode) {
      const whereQuery = {
        mandate_type: EnachStatusCode.MANDATE_TYPE,
        company_code: companyCode,
      };
      const params = {
        tablename: tableNamePaymentConstants?.SponsorBank,
        attributes: fetchBankCodeAttributes,
        whereQuery: whereQuery,
        order: DtRepresentationTableOrder,
      };
      apiCall
        .post(apiConstants?.findData, params)
        .then((response) => {
          if (response.status === 200) {
            let result = response?.data?.response;
            result = result.map((item) => {
              const new_item = {
                key: item?.bank_name,
                value: item?.bank_code,
                tooltipTitle: item?.bank_code,
                customValue : item?.upi_flag
              };
              return new_item;
            });
            result = result.filter((item) => item?.value != 100)
            setBankCode([...result]);
          }
        })
        .catch((error) => {
          toast.error(errMessage?.InternalServerError, {
            toastId: toastIds?.InternalServerError,
          });
        });
    }
  };

  const fetchProductType = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.Product,
        attributes: [
          attributePaymentConstants?.productCode,
          attributePaymentConstants?.productName,
          attributePaymentConstants?.upiFlag
        ],
      })
      .then((response) => {
        if (response.status === 200) {
          let result = response?.data?.response;
          result = result.map((item) => {
            const new_item = {
              key: item?.product_name,
              value: item?.product_code,
              upi_flag : item?.upi_flag
            };
            return new_item;
          });
          result.splice(0, 0, {
            key: " Please Select Product Type",
            value: "",
          });
          setProductType([...result]);
        }
      })
      .catch((error) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };

  const fetchSourceSystem = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: tableNamePaymentConstants?.SourceSystemMaster,
        attributes: [attributePaymentConstants?.SourceSystemName],
      })
      .then((response) => {
        if (response.status === 200) {
          response = response?.data?.response.map((item) => {
            const new_item = {
              key: item?.source_system_name,
              value: item?.source_system_name,
            };
            return new_item;
          });
          response.splice(0, 0, {
            key: " Please Select Source System",
            value: "",
          });
          setSourceSystem([...response]);
        }
      })
      .catch((error) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };

  const fetchFrequency = () => {
    apiCall
      .post(apiConstants?.findData, {
        tablename: "frequency",
        attributes: ["frequency_code"],
        whereQuery: { isActive: 1 },
      })
      .then((response) => {
        if (response.status === 200) {
          response = response?.data?.response.map((item) => {
            const new_item = {
              key: item?.frequency_code,
              value: item?.frequency_code,
            };
            return new_item;
          });
          response.splice(0, 0, { key: " Please Select Frequency", value: "" });
          setFrequency([...response]);
        }
      })
      .catch((error) => {
        toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };

  const SystemFormDetails = useSelector((state) => {
    return state?.SingleMandate?.systemDetails;
  });
  useEffect(() => {
    console.log("SYSTEMFROMDETAILS",SystemFormDetails);
    setFormData(SystemFormDetails?.systemValues);
  }, [SystemFormDetails]);
  useEffect(() => { }, [formData]);

  useEffect(() => {
    fetchProductType();
    fetchSourceSystem();
    fetchFrequency();
    fetchCompanyCode();
    fetchMandateTypes();
    updateNoBank();
  }, []);

  const fetchCompanyCode = () => {
    let paramObj = {
      tablename: tableNamePaymentConstants?.Company,
      where: {
        isActive: 1,
      },
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        result = result.map((item) => {
          let newItem = {
            key: item?.company_name,
            value: item?.company_code,
          };
          return newItem;
        });
        result.splice(0, 0, {
          key: " Please Select Company Code",
          value: "",
        });
        setCompanyOptions([...result]);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      });
  };

  useEffect(() => {
    fetchBankCode(companyCode);
  }, [companyCode]);

  const handleChildComponentData = (updatedValue , upi_flag) => {
    console.log("#######!!!!!!!!!!!",updatedValue,"    ", upi_flag)
    const { setFieldValue } =formRef.current;
    setFieldValue("bank_code", updatedValue);
    setFieldValue("bank_upi_flag" , upi_flag  )
  };

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={{
          manual_mandate: EnachStatusCode.MANDATE_TYPE,
          sponsor_bank: formData?.sponsor_bank ? formData?.sponsor_bank : "",
          company_code: formData?.company_code ? formData?.company_code : "",
          product_type: formData?.product_type ? formData?.product_type : "",
          source_system: formData?.source_system ? formData?.source_system : "",
          bank_code: formData?.bank_code ? formData?.bank_code : "",
          loan_booking_system: EnachStatusCode.NOT_BANKED_SOURCE_SYSTEM,
          loan_number: formData?.loan_number ? formData?.loan_number : "",
          purpose: formData?.purpose ? formData?.purpose : formData?.purpose,
          web_top: formData?.web_top ? formData?.web_top : "",
          los_id: formData?.los_id ? formData?.los_id : "",
          frequency: formData?.frequency
            ? formData?.frequency
            : formData?.frequency,
          purpose: formData?.purpose ? formData?.purpose : "", //EnachStatusCode.NOT_BANKED,
          bank_upi_flag: formData?.bank_upi_flag ? formData?.bank_upi_flag : "",
          product_upi_flag: formData?.product_upi_flag
            ? formData?.product_upi_flag
            : "",
        }}
        validationSchema={validSchema}
        enableReinitialize={true}
        onSubmit={(values) => onContinue(values)}
      >
        {/* console.log(values,"values"); */}
        {({ values, handleBlur, handleChange, setFieldValue }) => (
          <Form>
            <div className="row system_details">
              <div className="row mt-3 gap-5">
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Manual Mandate" className="required" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="manual_mandate"
                    id="manual_mandate"
                    value="ENACH"
                    disabled={true}
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="manual_mandate" />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Company Code" className="required" />
                  </div>
                  <FormikSelect
                    label="Please select a value"
                    name="company_code"
                    options={companyOptions}
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    handleChange={setCompanyCode(values.company_code)}
                  />
                  <div className="errorMessage">
                    <ErrorMessage name="company_code" />
                  </div>
                </div>
              </div>
              <div className="row mt-3 gap-5">
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Product Type" className="required" />
                  </div>
                  <FormikSelect
                    label="Please select a value"
                    name="product_type"
                    options={productType}
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                  />
                  <div className="errorMessage">
                    <ErrorMessage name="product_type" />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Source System" className="required" />
                  </div>
                  <FormikSelect
                    label="Please select a value"
                    name="source_system"
                    options={sourceSystem}
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                  />
                  <div className="errorMessage">
                    <ErrorMessage name="source_system" />
                  </div>
                </div>
              </div>
              <div className="row mt-2 gap-5">
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Bank Code" className="required" />
                  </div>
                  <DropdownWithTooltip
                    options={bankCode}
                    label="Please select a value"
                    onSelectCallback={(updatedValue, upi_flag) =>
                      handleChildComponentData(updatedValue, upi_flag)
                    }
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    defaultValue={formData?.bank_code}
                  />
                  <div className="errorMessage">
                    <ErrorMessage name="bank_code" />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Loan Booking System" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="loan_booking_system"
                    id="loan_booking_system"
                    value="FINNONE"
                    disabled={true}
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="loan_booking_system" />
                  </div>
                </div>
              </div>
              <div className="row mt-2 gap-5">
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Purpose" className="required" />
                  </div>
                  <FormikSelect
                    label="Please select a value"
                    name="purpose"
                    options={noBank}
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    handleChange={values.purpose[noBank]}
                  />
                  <div className="errorMessage">
                    <ErrorMessage name="purpose" />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel
                      value="Loan Number"
                      className={
                        values.purpose !== "New Customer" ? "required" : ""
                      }
                    />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    name="loan_number"
                    id="loan_number"
                    placeholder="Please enter loan number"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="loan_number" />
                  </div>
                </div>
              </div>
              <div className="row mt-2 gap-5">
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Web Top" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="web_top"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    id="web_top"
                    placeholder="Please enter web_top"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="web_top" />
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Application ID" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="los_id"
                    id="los_id"
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                    placeholder="Please enter application ID"
                  ></Field>
                  <div className="errorMessage">
                    <ErrorMessage name="los_id" />
                  </div>
                </div>
              </div>
              <div className="row mt-3 gap-5">
                <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Frequency" className="required" />
                  </div>
                  <FormikSelect
                    label="Please select frequency"
                    name="frequency"
                    options={frequency}
                    disabled={state?.type === FOMRS.EDIT ? true : false}
                  />
                  <div className="errorMessage">
                    <ErrorMessage name="frequency" />
                  </div>
                </div>
                {/* <div className="col-md-5">
                  <div className="field_name">
                    <FormLabel value="Purpose" />
                  </div>
                  <Field
                    type="text"
                    className="InputField"
                    name="purpose"
                    id="purpose"
                    value="Not banked"
                    disabled={true}
                  ></Field>
                </div> */}
              </div>
              <div className="d-flex pt-3 gap-3">
                <button type="submit" className="system_details-nextBtn">
                  Next
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
}
export default SystemDetails;
