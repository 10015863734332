import CustomTable from "../../../../component/customtable/CustomTable";
import Button from "../../../../component/common/Button/button";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import "./chequeChecker.scss";
import { ROUTE_PATH } from "../../../../constants/Routes";
import apiCall from "../../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  errMessage,
  FOMRS,
  STATUS,
  successMsg,
  tableNamePaymentConstants,
  toastIds,
} from "../../../../constants/Common/apiConstants";
import {
  commonVariables,
  tableID,
} from "../../../../constants/Common/commonConstants";
import SystemXLoader from "../../../../component/common/Loader/SystemXLoader";
import { toast } from "react-toastify";
import ApproveConfirmation from "../../../../component/ApproveModal/approveModal";

function ChequeChecker() {
  const navigate = useNavigate();
  const [pageNumber, setPageNumber] = useState(1);
  const [listdata, setListdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [datacount, setDatacount] = useState(0);
  const [dataloader, setDataloader] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState({});

  const approveFunc = () => {
    let query = {
      body: {
        isApprove:1,
      },
      whereQuery :deleteItemId,
      tablename: 'cheque_master',
    };
    setLoading(true);
    apiCall.post(apiConstants?.updateUser, query).then(async (res) => {
      if (res?.status === STATUS.SUCCESS) {
        setShowDeleteConfirmationModal(false);
        toast.success("Successfully approve Cheque Details");
      }
      })
      .catch((er) => {
        console.log("err", er);
        toast.error(er?.message||errMessage?.somethingWentWrong,toastIds?.SomethingWentWrong);
      })
      .finally(() => {
        setLoading(false);
        listApi();
      });
  }

  const listApi = (value) => {
    let temp = 0;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 10 : (pageNumber - 1) * 10;
    }
    setLoading(true);
    setDataloader(true);
    apiCall
      .post(apiConstants?.fetchAllData, {
        // offset: temp || 0,
        // limit: 10,
        // tablename: "cheque_master",
        // month: 0,
        tablename:"cheque_master" ,
        month: 0,
        offset: temp ||0,
        order: [["id", "desc"]],
        limit: 10,
        dataFetch: [
          {
            column: "isApprove",
            operator: "ne",
            value: "1",
          }
        ],
      })
      .then(async (res) => {
        if (res.status === 200) {

          let result = res?.data?.response;
          setDatacount(res?.data?.count || 0);
          result = result.map((item, index) => {
            let newItem = {
              no: temp + index + 1,
              accountNo: item?.account_no ? item.account_no : "-",
              lotNo: item?.lot_no ? item.lot_no : "-",
              chequeFrom: item?.cheque_from
                ? item.cheque_from
                : "-",
              chequeTil: item?.cheque_til
                ? item.cheque_from
                : "-",
              createdBy: item?.createdBy
                ? item.createdBy
                : "-",
              instrumentType: item?.instrument_type
              ? item?.instrument_type === 1
              ? "Yes"
              : "No"
            : "No",
              remark: item?.remark
                ? item.remark
                : "-",
              companyCode: item?.company_code
                ? item.company_code
                : "-",
              chequeLastUsed: item?.cheque_lastUsed
                ? item.cheque_lastUsed
                : "-",
              isActive: item?.isActive
                ? item?.isActive === 1
                  ? "Yes"
                  : "No"
                : "No",
              action:addButton(item?.id)
            };
            return newItem;
          });
          if (result?.length > 0) {
            setListdata([...result]);
          }else{
            setListdata();
          }
        }
      })
      .catch((er) => {
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
        setDataloader(false);
      });
  };

  const changePage = (value) => {
    listApi(value);
    setPageNumber(value - 1);
  };

  const addButton = (param) => {
    return (
      <>
        <div className="d-flex justify-content-between gap-2 rptTableButton">
          <div>
            <button
              className="approveBtn"
              onClick={() => {
                setDeleteItemId({
                  id:param
                })
                setShowDeleteConfirmationModal(true)
              }}
            >
              {commonVariables.APPROVE}
            </button>
          </div>
          {/* <div>
            <button
              className="rejectBtn"
              onClick={() => {
                rejectMerge(param);
              }}
            >
              {commonVariables.REJECT}
            </button>
          </div> */}
        </div>
      </>
    );
  };

  const approveMerge = (id) => {
    let query = {
      body: {
        isApprove:1,
      },
      whereQuery : {
        id: id,
      },
      tablename: 'cheque_master',
    };
    setLoading(true);
    apiCall.post(apiConstants?.updateUser, query).then(async (res) => {
      if (res?.status === STATUS.SUCCESS) {
        toast.success("Successfully approve Cheque Details");
      }
      })
      .catch((er) => {
        console.log("err", er);
        toast.error(er?.message||errMessage?.somethingWentWrong,toastIds?.SomethingWentWrong);
      })
      .finally(() => {
        setLoading(false);
        listApi();
      });
  };

  useEffect(() => {
    listApi();
  }, []);
  return (
    <>
      <div id="Company">
        {/* <div className="alignHeader">
					<div className="larger_f_bold blueFont setMargin">Company</div>
					<div className="setMargin">
						<Button
							value={'Create Company'}
							role="primary"
							onClick={() => {
								navigate(ROUTE_PATH?.CREATE_COMPANY, {
									state: {
										type: FOMRS.ADD,
									},
								});
							}}
						/>
					</div>
				</div> */}
        <div className="d-flex flex-wrap align-items-center justify-content-between mt-4 mb-4 mx-3">
          <div className="right-div-header">
            <div>
              <label className="labelPresentation">Cheque Checker</label>
            </div>
          </div>
          <div className="d-flex gap-3 left-div-header">
            <div>
              <ApproveConfirmation
                showModal={showDeleteConfirmationModal}
                hideModal={() => setShowDeleteConfirmationModal(false)}
                confirmModal={approveFunc}
                id={deleteItemId}
                type={FOMRS.DELETE}
                message="Are you sure you want to Approve this item?"
              />
              {/* <Button
                value={"Create Cheque Master"}
                role="primary"
                onClick={() => {
                  navigate(ROUTE_PATH?.CREATE_CHEQUEMASTER, {
                    state: {
                      type: FOMRS.ADD,
                    },
                  });
                }}
              /> */}
            </div>
          </div>
        </div>

        <div className=" dtMergeTable">
          {dataloader && (
            <div className="loaderDiv">
              <SystemXLoader />
            </div>
          )}
          <CustomTable
            cols={[
              {
                id: tableID?.NO || "",
                title: commonVariables?.NO || "",
              },
              {
                id: "accountNo" || "",
                title: "Account No" || "",
              },
              {
                id: "lotNo" || "",
                title: "Lot No" || "",
              },
              {
                id: "chequeFrom" || "",
                title: "Cheque From" || "",
              },
              {
                id: "chequeTil" || "",
                title: "Cheque Til" || "",
              },
              {
                id: "createdBy" || "",
                title: "created By" || "",
              },
              {
                id: "instrumentType" || "",
                title: "Instrument Type" || "",
              },
              {
                id: "remark" || "",
                title: "Remark" || "",
              },
              {
                id: tableID?.COMPANY_CODE || "",
                title: commonVariables?.COMPANY_CODE || "",
              },
              {
                id: "chequeLastUsed" || "",
                title: "Cheque Last Used" || "",
              },
              {
                id: tableID?.IS_ACTIVE || "",
                title: commonVariables?.IS_ACTIVE || "",
              },
              {
                id: tableID?.ACTION || "",
                title: commonVariables?.ACTION || "",
              },
            ]}
            data={listdata}
            count={datacount}
            setPagination={(page) => changePage(page)}
            activePage={pageNumber}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
}

export default ChequeChecker;
