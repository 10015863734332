import React, { memo, useCallback, useEffect, useState } from "react";
import "./RegularPaymentMaker.scss";
import Payment from "../../../component/Payment/Payment";
import CustomTable from "../../../component/customtable/CustomTable";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  commonVariables,
  dateFormats,
  dummyList,
  paymentDropdownValues,
} from "../../../constants/Common/commonConstants";
import { Button, Form, Modal } from "react-bootstrap";
import {
  apiConstants,
  errMessage,
  successMsg,
  toastIds,
} from "../../../constants/Common/apiConstants";
import moment from "moment";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { chain, difference, filter as _filter } from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { errorMessages } from "../../../constants/Common/errorConstants";
import CustomModal from "../../../component/modal/modal";

const RegularPaymentMaker = () => {
  const [tableLoading, setTableLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [listdata, setListdata] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(true);
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (checkedData?.length) {
      setShow(true);
    } else {
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });
    }
  };
  let LoginState = useSelector((state) => state.login);

  const handleCheckAll = (checkAll) => {
    if (checkAll) {
      setCheckedData(
        chain(listdata)
          .map((e) => (e.disabled ? undefined : e || e))
          .filter()
          .value()
      );
    } else {
      setCheckedData([]);
    }
  };
  const checkboxFormatter = (row, idx) => (
    <div className="checkBoxForm">
      <input
        className=" checkboxTablerow"
        type="checkbox"
        disabled={row.disabled}
        onChange={() => {
          console.log("Checkbox clicked:", row);
          setCheckedData((prevCheckedData) => {
            if (prevCheckedData.includes(row)) {
              return prevCheckedData.filter(
                (item) => item?.loanAccNo !== row?.loanAccNo
              );
            } else {
              return [...prevCheckedData, row];
            }
          });
        }}
        checked={checkedData.includes(row)}
        id="flexCheckDefault"
      />
    </div>
  );
  
  const releaseApi = () => {
    if (checkedData?.length) {
      let userData = LoginState?.decodedData;
      let DataTmp = JSON.parse(localStorage.getItem(paymentDropdownValues));
      let parsedData = Object.assign({}, ...DataTmp);
      let apiBody = {
        ids: checkedData?.map((val) => {
          return val.id;
        }),
        user_name: userData?.user_name || "", // take username and user code from session
        user_code: userData?.user_code || "",
      };
      setUpdateLoading(true);
      apiCall
        .post(apiConstants?.ApiPaymentSentForApproval, apiBody)
        .then((res) => {
          if (res.data.status === 200) {
            let Success = res?.data?.response?.count;
            setModalData({ "Send for Approval": Success});
            setShowModal(true);
            toast.success(
              `${checkedData?.length} cases have been sent for approval.`,
              {
                toastId: toastIds?.recordSentSuccessfully,
              }
            );
          } else {
            toast.error(res.data.message, {
              toastId: toastIds?.recordSentSuccessfully,
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          toast.error(err, {
            toastId: toastIds?.recordSentSuccessfully,
          });
        })
        .finally(() => {
          setCheckedData([]);
          listApi(null, true);
          setUpdateLoading(false);
        });
    } else {
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });
    }
  };
  const updateisApiCallState = (newState) => {
    setIsApiCall(newState);
  };

  const Table = () => {
    return (
      <div
        className="RegularPayment_tableAlignment"
        style={listdata?.length <= 0 ? { display: "none" } : {}}
      >
        <CustomTable
          cols={[
            {
              id: "id",
              checkbox: true,
              allChecked: listdata.length
                ? difference(
                  listdata.map((e) => e),
                  checkedData
                ).length === 0
                : false,
              handleCheckboxAction: () =>
                handleCheckAll(
                  difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length !== 0
                ),
              formatter: checkboxFormatter,
            },
            {
              id: "srNo",
              title: "#",
            },
            {
              id: "loanAccNo",
              title: "Loan Account No",
            },
            {
              id: "productDesc",
              title: "Product Description",
            },
            {
              id: "branch",
              title: "Branch Name",
            },
            {
              id: "journalNo",
              title: "Journal No / Payment Ref No",
            },
            {
              id: "disAmt",
              title: "Disbursement Amount",
            },
            {
              id: "cheque/dd no",
              title: "Cheque / DD No",
            },
            {
              id: "Cheque_DD_Date",
              title: "Cheque DD Date",
            },
            {
              id: "transactionDate",
              title: "Transaction Date",
            },
            {
              id: "applicantName",
              title: "Applicant Name",
            },
            {
              id: "payTo",
              title: "Pay to",
            },
            {
              id: "txnIMBSName",
              title: "Txn IMPS Bene Name",
            },
            {
              id: "beneNameScore",
              title: "Score %",
            },
            {
              id: "status",
              title: "Status",
            },
            {
              id: "beneBankName",
              title: "Bene Bank Name",
            },
            {
              id: "Bene_Bank_Acct",
              title: "Bene Bank Account",
            },
            {
              id: "ifscCode",
              title: "IFSC Code",
            },

            {
              id: "paymentMode",
              title: "Payment Mode",
            },
            {
              id: "businessId",
              title: "Business ID",
            },
            {
              id: "webTop",
              title: "Webtop",
            },
            {
              id: "caseStatus",
              title: "Case Status",
            },
            {
              id: "cas_no",
              title: "CAS_No",
            },
          ]}
          data={listdata}
          count={count}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          // holdFunction={() => updateStatus(commonVariables?.Hold)}
          rejectFunction={() => handleShow()}
          // releasePayment={() => releasePayment()}
          releaseApi={() => releaseApi()}
          loading={tableLoading}
          updateLoading={updateLoading}
          isRegularPaymentMaker
        isApiCall={isApiCall}
        updateisApiCallState={updateisApiCallState}
        />
      </div>
    );
  };

  const listApi = (value, showErr) => {
    let temp = 0;
    let initialLimit = 300;
    if ((value || pageNumber) > 0) {
      temp = value ? (value - 1) * 300 : (pageNumber - 1) * 300;
    }
    let DataTmp = JSON.parse(localStorage.getItem(paymentDropdownValues));
    if (DataTmp) {
      setTableLoading(true);
      let parsedData = Object.assign({}, ...DataTmp);
      let paramObj = {
        offset: temp,
        limit: initialLimit,
        type: commonVariables?.Regular,
        company_code: parsedData["Company"] || "",
        sponsor_bank: parsedData["Sponsor Payment Bank"] || "",
        pay_to: parsedData["Vendor Type"]?.toLowerCase() || "",
        scheme_code: "",
        product_description: parsedData["Product Code"] || ""

      };
      apiCall
        .post(apiConstants?.fetchPayment, paramObj)
        .then((res) => {
          if (res?.data?.status === 200) {
            setIsApiCall(true);
            let result = res?.data?.response?.result;
            if (result?.length) {

              result = result.map((item, index) => {
                let newItem = {
                  srNo: index + 1,
                  loanAccNo: item?.loan_no || "-",
                  id: item?.id || "",
                  productDesc: item?.product_description || "-",
                  branch: item?.branch_name || "-",
                  journalNo: item?.fund_reference_id || "-",
                  disAmt: item?.disbursment_amount || "-",
                  "cheque/dd no": item?.cheque_dd_no || "-",
                  transactionDate:
                    moment(item.createdAt).format(dateFormats?.dmyHms) || "-",
                  applicantName: item?.applicant_name || "-",
                  payTo: item?.pay_to || "-",
                  Cheque_DD_Date:
                  moment(item.cheque_dd_date).format(dateFormats?.dmy) || "-",
                  Bene_Bank_Acct: item?.bene_bank_acct || "-",
                  txnIMBSName: item?.txn_imps_bene_name || "-",
                  beneNameScore: item?.bene_name_match_score || "-",
                  status: item?.acct_validation_status || "-",
                  beneBankName: item?.bene_bank_name || "-",
                  ifscCode: item?.beneficiary_bank_ifsc || "-",
                  paymentMode: item?.transaction_type || "-",
                  businessId: item?.business_id || "-",
                  webTop: item?.webtop_id || "-",
                  caseStatus: item?.case_status || "-",
                  cas_no: item?.cas_no || "-",
                };
                return newItem;
              });
            } else {
              setListdata([]);
              toast.error(res.data.message || errorMessages.SOMETHING_WRONG, {
                toastId: toastIds?.DataError,
              });
            }
            if (result?.length > 0) {
              setListdata([...result]);
              setCount(res?.data?.response?.pages * 10);
            }
          } else {
            setListdata([]);
            if (!showErr) {
              toast.error(res?.data?.message || errorMessages.SOMETHING_WRONG, {
                toastId: toastIds?.DataError,
              });
            }
          }
        })
        .catch((er) => {
          if (!showErr) {
            toast.error(er.message || errorMessages.SOMETHING_WRONG, {
              toastId: toastIds?.DataError,
            });
          }
          setListdata([]);
        })
        .finally(() => {
          setTableLoading(false);
        });
    }
  };
  const changePage = (value) => {
    setPageNumber(value);
  };

  useEffect(() => {
    listApi(pageNumber);
  }, [pageNumber]);

  const setView = () => {
    setTimeout(() => {
      listApi();
    }, 400);
  };
  return (
    <>
      {modalData && (
        <CustomModal
          heading="Payment Status"
          data={modalData}
          show={showModal}
          onHide={() => setShowModal(false)}
        />
      )}
      <div className="mt-4 mx-3 pt-2 ">
        <label className="common-header cursor-pointer pb-3">
          Regular Payment (Maker)
        </label>
        <div className="container-fluid ">
          <div className="row regular-payment-wrapper-row mb-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
              <Payment setView={() => setView()} isRegularPayment />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0">
              <div className="RegularPayment_max-widthTable">
                {tableLoading || updateLoading ? (
                  <div className="text-center spinnerDiv">
                    <SystemXLoader />
                    <p>
                      <b>Loading...</b>
                    </p>
                  </div>
                ) : (
                  <Table />
                )}
              </div>
            </div>
          </div>
        </div>
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="text-center" closeButton>
            <Modal.Title>Reject Payment</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <h4>Are you sure ?</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                // updateStatus(commonVariables?.Reject);
                handleClose();
              }}
              className="mr-4 modalBtnCstm"
            >
              Yes
            </Button>
            <Button variant="secondary" onClick={handleClose}>
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default memo(RegularPaymentMaker);