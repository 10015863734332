import React, { useEffect, useState } from "react";
import "./Registermandate.scss";
import logo from "../../../Images/TCL-newlogo.jpg";
import upiLogo from '../../../Images/upi-ar21.svg'
import logoImage from "../../../Images/eNachNPCIlogo.png";
import { useParams } from "react-router-dom";
import apiCall from "../../../utils/apiFunction/apiCall";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import FormLabel from "../../../component/common/formLabel/formLabel";
import { Formik, Form, Field, ErrorMessage } from "formik";
import {
  apiConstants,
  enachTechProcessConstants,
  tableNamePaymentConstants,
  registerMandateManualAttribute,
  errMessage,
  toastIds
} from "../../../constants/Common/apiConstants";

import { YupMessages , EnachUPIPaymentModes  , EnachStatusCode} from "../../../constants/Common/commonConstants";
import { object, string } from "yup";
import { toast } from "react-toastify";


const UPITransactionICICI = () => {
  const [errorFlag, setErrorFlag] = useState(0);
  const [response, setResponse] = useState({});
  const [downloadFlag, setDownloadFlag] = useState(false);
  const [openDiv, setOpenDiv] = useState(0);
  const [qrCodeImage , setQrCodeImage] = useState()
  const [auth , setAuth] = useState(false)


  const { key } = useParams();

  const payViaUPI = (params) => {
    setDownloadFlag(true);
    params.key = key;

    apiCall.post(enachTechProcessConstants?.upiTransactionProcess , params).then((response) => {
      if(response.status === 200){
        setDownloadFlag(false)
        if(response?.data?.status !== 200 ) return toast.error(response?.data?.message , toastIds?.InternalServerError)
        switch(response?.data?.message){
          case 'UPI_INTENT':
            window.location.href = response?.data?.response
            return setTimeout(()=> {
              window.location.href =  `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`
            },15000)

          case 'qr_code':
            setQrCodeImage(response?.data?.response)
            return setTimeout(()=> {
              window.location.href = `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`
            },15000)
            
          default:
            return window.location.href =  `${enachTechProcessConstants?.upiTransactionPollingPage}/${key}`

        }

      
      }
    }).catch((error) => {
      console.log('Error' , error)
      setDownloadFlag(false)
      toast.error(
        errMessage?.InternalServerError,
        toastIds?.InternalServerError
      );
    }) 
  }


  const fetchResponseStatus = () => {
    const params = {
      tablename: tableNamePaymentConstants?.ManualMandate,
      whereQuery: { bitly_sms_status: key },
      attributes: registerMandateManualAttribute,
    };
    apiCall
      .post(apiConstants?.findData, params)
      .then((renderResponse) => {
        if (renderResponse?.status === 200) {
          if((renderResponse.data.response[0].enach_mandate_id === "") && EnachStatusCode.MANDATE_STATUS_CODES.includes(renderResponse.data.response[0].mandate_status) ) {
            alert('Your link has been expired as you have already completed your ENACH Journey.')
            window.open("about:blank", "_self");
          }else{
            setAuth(true)
            setResponse(renderResponse?.data?.response[0]);
          }
        }
      })
      .catch((error) => {
        return setErrorFlag(1);
      });
  };

 
  const validSchema = object({
    vpa: string().required(YupMessages?.VPARequired)
  });

  useEffect(() => {
    fetchResponseStatus();
  }, []);

  return (
    <>
    {
      auth === true && <div className="UPIloader">
      {downloadFlag && (
        <div className="className='row justify-content-center d-flex loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <Formik
        initialValues={{
          vpa: '',
        }}
        enableReinitialize={true}
        validationSchema = {validSchema}
        onSubmit={(values) =>  {
          values.upi_method = EnachUPIPaymentModes.UPI_ID 
          payViaUPI(values)
        }}

      
      >
        {(props) => {
          const { values } = props;
          return (
            <Form>
              <div className="container-fluid bg">
                <div>
                  <nav className="main_navbar d-flex justify-content-center justify-content-lg-end ">
                    <h5 className="mr-2 mt-1 ">
                      <i className="fa fa-envelope"></i>{" "}
                      <label className="mr-2 text-right">
                        customercare@tatacapital.com
                      </label>
                      <i className="fa fa-phone "></i>{" "}
                      <label>1860 267 6060</label>
                    </h5>
                  </nav>
                  <nav className="mynavbar navbar">
                    <div className="container d-flex justify-content-center justify-content-lg-between">
                      <img className="logo" src={logo} alt="logo" />
                      <img
                        className="secondimg"
                        src={logoImage}
                        alt="logo"
                      ></img>
                    </div>
                  </nav>
                </div>

                <div className="container-fluid">
                  <div className="container row justify-content-center d-flex mx-auto ">
                    <div className="row ps-5 pt-3"></div>
                    <div className=" col-md-25 containerstyleUPI ">
                      <div className="row ">
                        <div className="container d-flex justify-content-center pt-4">
                          <img className="logo" src={upiLogo} alt="logo" />
                        </div>
                        <div className="">
                          <hr></hr>
                        </div>
                      </div>
                      <div className="col justify-content-center pt-6 mb-2">

                        <div className="upiCollect border m-2 col-lg-5 col-12 mx-auto">

                          <div className="p-3">
                            <input
                              type="radio"
                              id="debitCardRadio"
                              name="paymentOption"
                              onChange={(e) => setOpenDiv(1)}
                              value="upi_id"
                            />

                            <label htmlFor="debitCardRadio">Pay By Entering UPI ID</label>

                          </div>
                          {openDiv === 1 && <div>
                            <div className="col-lg-12 col-md-6 col-sm-12 ">
                              <div className="field_name">
                                <FormLabel
                                  value="Enter your UPI ID"
                                  className="required"
                                />
                              </div>
                              <Field
                                type="text"
                                className="form-control form-control-lg pt-1 w-100 h-25"
                                name="vpa"
                                id="vpa"
                                placeholder="UPI ID"
                              ></Field>
                              <div className="errorMessage">
                                <ErrorMessage name="vpa" />
                              </div>
                            </div>
                            <div className="d-flex justify-content-center pt-2 mt-2 mb-4 ">
                              <button
                                id="ok"
                                type="submit"
                                className="custombtn"
                                onClick={() => { }}
                              >
                                VERIFY & PAY
                              </button>
                            </div>
                          </div>}




                        </div>
                        <div className="upiApp border mb- col-lg-5 col-12 mx-auto d-block d-sm-none">

                          <div className="p-3">
                            <input
                              type="radio"
                              id="debitCardRadio"
                              name="paymentOption"
                              onChange={(e) => setOpenDiv(2)}
                              value="debitCard"
                            />

                            <label htmlFor="debitCardRadio">Pay By UPI App</label>

                          </div>
                          {openDiv === 2 && <div>
                            <div className="d-flex justify-content-center pt-2 mt-2 mb-4 ">
                              <button
                                id="ok"
                                type="button"
                                className="custombtn"
                                onClick={() => {
                                  const params = {upi_method : 'UPI_INTENT'}
                                  payViaUPI(params)
                                 }}
                              >
                                PAY NOW
                              </button>
                            </div>
                          </div>}


                        </div>
                        <div className="upiQR border m-2 col-lg-5 col-12 mx-auto d-none d-sm-block">

                          <div className="p-3">
                            <input
                              type="radio"
                              id="debitCardRadio"
                              name="paymentOption"
                              onChange={(e) => setOpenDiv(3)}
                              value="debitCard"
                            />

                            <label htmlFor="debitCardRadio">Pay By QR Code</label>

                          </div>
                          {openDiv === 3 && <div className="d-flex justify-content-center pt-2 mt-2 mb-4">
                            {
                              qrCodeImage ? 
                              <div>

                                  <img src={qrCodeImage} alt="API Response" />


                              </div> 

                                :

                                <button
                                id="ok"
                                type="button"
                                className="custombtn"
                                onClick={() => {
                                  const params = {upi_method : 'qr_code'}
                                  payViaUPI(params)
                                }}
                              >
                                GENERATE QR CODE
                              </button>
                            }

                          </div>}
                        </div>
                        <div className="p-2"></div>
                      </div>
                    </div>
                    <div className="pt-2 mt-2 mb-4"></div>
                  </div>

                </div>

                <nav className="footer navbar d-flex justify-content-center">
                  <label>Copyright © 2020 Tata Capital</label>
                </nav>
              </div>


            </Form>
          );
        }}
      </Formik>
    </div>
    }

    </>
  );
};

export default UPITransactionICICI;
