import React, { useState } from "react";
import "./Callback.scss";
import PartnerPayments from "../../../component/PartnerPayments/PartnerPayments";
import CustomTable from "../../../component/customtable/CustomTable";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  errMessage,
  paymentType,
  toastIds,
  updateStatusApi,
} from "../../../constants/Common/apiConstants";
import moment from "moment";
import { chain, difference, filter as _filter } from "lodash";
import {
  dateFormats,
  dummyList,
  paytmPaymentDropdownValues,
} from "../../../constants/Common/commonConstants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { errorMessages } from "../../../constants/Common/errorConstants";

const Callback = () => {
  const [loading, setLoading] = useState(false);
  const [listdata, setListdata] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [checkedData, setCheckedData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(true);
 
  const [documentTableDummy, setDocumentTableDummy] = useState([
    ...dummyList.map((value, index) => {
      value.id = index + 1;
      return value;
    }),
  ]);
  const [pageNumber, setPageNumber] = useState(0);
  let LoginState = useSelector((state) => state.login);

  const handleCheckAll = (checkAll) => {
    if (checkAll) {
      setCheckedData(
        chain(listdata)
          .map((e) => (e.disabled ? undefined : e || e))
          .filter()
          .value()
      );
    } else {
      setCheckedData([]);
    }
  };
  const checkboxFormatter = (row, idx) => (
    <div className="checkBoxForm">
      <input
        class="form-check-input"
        type="checkbox"
        className="checkboxTablerow"
        disabled={row.disabled}
        onChange={() => {
          if (checkedData.includes(row || row)) {
            setCheckedData(checkedData.filter((e) => e !== (row || row)));
          } else {
            setCheckedData([...checkedData, row || row]);
          }
        }}
        checked={checkedData.includes(row || row)}
        id="flexCheckDefault"
      ></input>
    </div>
  );
  const updateStatus = (type) => {
    if (checkedData?.length) {
      let userData = LoginState?.decodedData;
      let apiBody = {
        ids: checkedData?.map((val) => {
          return val.id;
        }),
        case_status: type, //hold, regular ,exception
        user_name: userData?.user_name || "",
        user_code: userData?.user_code || "",
      };
      setLoading(true);
      apiCall
        .post(updateStatusApi, apiBody)
        .then((res) => {
          toast.success(errMessage?.PaymentHoldSuccessful, { toastId: toastIds?.PaymentHoldedSuccessfully });
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          listApi(null, true);
          setLoading(false);
        });
    } else {
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });
    }
  };

  const releaseCallbackApi = () => {
    if(checkedData?.length)
    {
      let DataTmp = JSON.parse(localStorage.getItem(paytmPaymentDropdownValues));
      let parsedData = Object.assign({},...DataTmp);

      let apiBody = {
        ids: checkedData?.map((val) => {
          return val.id;
        }),
      };
      setLoading(true);
      apiCall.post(apiConstants?.ApiPaymentReportCallback,apiBody).then((res) => {
        if(res.status == 200){
          console.log(">>>><<<<<<<<<<>>>>>>>>><<<<<<<");
        }
        else{
          toast.error(res.data.message, {
            toastId: toastIds?.recordSentSuccessfully,
          });
        }

      }).catch((err) => {
        console.log("err>>>>>>", err);
        toast.error(err, {
          toastId: toastIds?.recordSentSuccessfully,
        });

      }).finally(() => {
        setCheckedData([]);
        listApi(null, true);
        setUpdateLoading(false);
      })
    }
    else{
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });

    }

  }

  const updateisApiCallState = (newState) => {
    setIsApiCall(newState);
  };





  const Table = () => {
    return (
      <div
        className="RegularPayment_tableAlignment"
        style={listdata?.length <= 0 ? { display: "none" } : {}}
      >
        <CustomTable
          cols={[
            {
              id: "id",
              checkbox: true,
              allChecked: listdata.length
                ? difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length === 0
                : false,
              handleCheckboxAction: () =>
                handleCheckAll(
                  difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length !== 0
                ),
              formatter: checkboxFormatter,
            },
            {
              id: "srNo",
              title: "#",
            },
            {
              id: "loanAccNo",
              title: "LAN",
            },
            {
              id: "companyCode",
              title: "Company Code"
            },
            {
              id: "customerName",
              title: "Customer Name"
            },
            {
              id:"responseCode",
              title: "Response_Code"
            },
            {
              id:"responseDesc",
              title: "Response_Desc"
            },
            {
              id:"opportunityID",
              title:"Opportunity_ID"
            },
            {
              id:"applicationId",
              title:"Application_ID"
            },
            {
              id:"utrNumber",
              title:"UTR_Number"
            },
            {
              id:"paymentAmount",
              title:"Payment_Amount"
            },
            {
              id:"paymentDate",
              title:"Payment_Date"
            }
          ]}
          data={listdata}
          count={5}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          releaseCallbackApi={() => releaseCallbackApi()}
          loading={loading}
          updateLoading={updateLoading}
          isCallback
          isApiCall={isApiCall}
          updateisApiCallState={updateisApiCallState}
        />
      </div>
    );
  };


  const listApi = (value, showErr) => {
    let temp = value
    
      ? (value - 1) * 10
      : pageNumber > 0
      ? (pageNumber - 1) * 10
      : 0;
    setLoading(true);
    let DataTmp = JSON.parse(localStorage.getItem(paytmPaymentDropdownValues));
    let parsedData = Object.assign({}, ...DataTmp);
    let paramObj = {
      offset: temp || 0,
      limit: 300,
      company_code: parsedData["Company"] || "",
      source_system_name: parsedData["Source System"]
    };
    apiCall
      .post(apiConstants?.fetchPaytmCallback, paramObj)
      .then((res) => {
        if (res.status === 200) {
          let result = res.data.response;
          setIsApiCall(true);
          if (result?.length) {
            result = result.map((item, index) => {
              let newItem = {
                srNo: index + 1,
                id: item?.id || "",
                companyCode: item?.company_code || "-",
                customerName: item?.customer_name || "-",
                responseCode: item?.txn_status_code || "-",
                responseDesc: item?.txn_status_desc || "-",
                loanAccNo: item?.loan_number || "-",
                opportunityID: item?.opportunity_id || "-",
                applicationId: item?.source_application_reference || "-",
                utrNumber: item?.utr_no || "-",
                paymentAmount: item?.amount || "-",
                paymentDate: item?.createdAt || "-"
              };
              return newItem;
            });
          } else {
            setListdata([]);
            if(!showErr){
            toast.error(res.data.message || errorMessages.SOMETHING_WRONG, { toastId: toastIds?.DataError });
            }
          }
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        if(!showErr){
        toast.error(er.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
        setListdata([]);
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const changePage = (value) => {
    setPageNumber(value - 1);
  };

  const setView = () => {
    setTimeout(() => {
      listApi();
    }, 400);
  };
  return (
    <>
      <div className="mt-4 pt-2 mx-3">
        <p className="HoldPayment_holdPaymentTxt ">
          <b>Paytm Callback</b>
        </p>
        <div className="container-fluid ">
          <div className="row regular-payment-wrapper-row mb-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
              <PartnerPayments setView={() => setView()} isCallback />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0">
              <div className="HoldPayment_max-widthTable RegularPayment_max-widthTable">
                {loading ? (
                  <div className="text-center spinnerDiv">
                    <SystemXLoader />
                    <p>
                      <b>Loading...</b>
                    </p>
                  </div>
                ) : (
                  <Table />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Callback;
