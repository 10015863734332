import React from "react";
import * as Yup from "yup";
import "react-datepicker/dist/react-datepicker.css";
import DynamicFilterForm from "../../common/DynamicFilterForm/DynamicFilterForm";

const PennyDropHeader = ({ onSubmit, loading }) => {
  const formConfig = [
    {
      name: "FromDate",
      type: "datepicker",
      placeholder: "From Date",
      validation: Yup.date().required("From Date is required"),
      initialValue: new Date(),
    },
    {
      name: "ToDate",
      type: "datepicker",
      placeholder: "To Date",
      validation: Yup.date().required("To Date is required"),
      initialValue: new Date(),
    },
    {
      name: "sourceSystem",
      type: "dropdown",
      options: [
        { value: "All", label: "ALL_Source" },
        { value: "jocata", label: "jocata" },
        { value: "TSL_J", label: "TSL_J" },
        { value: "INDmoney", label: "INDmoney" },
        { value: "FINNONE", label: "FINNONE" },
        { value: "sfdc", label: "sfdc" },
        { value: "MANUAL", label: "MANUAL" },
        { value: "BANCS", label: "BANCS" },
        { value: "TSL_Osian", label: "TSL_Osian" },
      ],
      validation: Yup.string(),
      initialValue: "All",
    },
    {
      name: "caseStatus",
      type: "dropdown",
      options: [
        { value: "All", label: "All" },
        { value: "S", label: "Success" },
        { value: "J", label: "Failed" },
      ],
      validation: Yup.string(),
      initialValue: "All",
    },
  ];

  const handleSubmit = (values) => {
    console.log("new form values Form values:", values);
    onSubmit(values);
  };

  return (
    <div className="d-flex align-items-center justify-content-between py-2 px-4">
      {/* Title */}
      <div
        className="d-flex align-items-center disbursementTable_disbursementTableTxt fw-bold"
        style={{ color: "#2A3A59", fontSize: "18px" }}
      >
        Account Validation Report
      </div>

      {/* Dynamic Form */}
      <DynamicFilterForm formConfig={formConfig} onSubmit={handleSubmit} loading={loading} />
    </div>
  );
};
export default PennyDropHeader;
