import React, { useEffect, useState } from "react";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import "../apiStatus/bulk.scss";
import { toast } from "react-toastify";
import moment from "moment";
import {attributePaymentConstants} from "../../../constants/Common/apiConstants"
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  errMessage,
  reportConstants,
  reportStatusAttributes,
  reversalApiBasedAttributes,
  tableNamePaymentConstants,
  toastIds,
} from "../../../constants/Common/apiConstants";
import {paymentDropdownValues} from "../../../constants/Common/commonConstants";

import {
  commonVariables,
  dateFormats,
} from "../../../constants/Common/commonConstants";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { useSelector } from "react-redux";
import downloadIcon from "../../../Images/download.svg";
import CustomTable from "../../../component/customtable/CustomTable";

const Reversalapibased = () => {
  const loginState = useSelector((state) => state.login);
  const [optionsValue,setOptionsValue] = useState([])
  const [formValues, setFormValues] = useState([
    {
      type: "dateinput",
      value: "",
      label: "From Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },
    {
      type: "dateinput",
      value: "",
      label: "To Date",
      placeholder: "Select Date",
      required: true,
      width: 6,
    },

    {
      type: "dropdown",
      value: "",
      label: "Bank Name",
      placeholder: "Please Select a value",
      required: true,
      width: 6,
      
  }

  ]);


  useEffect(() => {
    getSponsorPayment();
    localStorage.removeItem(paymentDropdownValues);
  }, []);

  useEffect(() => {
    settingFormValues();
  }, [
    optionsValue,
  ]);

  const settingFormValues = () =>{

    setFormValues([
      {
        type: "dateinput",
        value: "",
        label: "From Date",
        placeholder: "Select Date",
        required: true,
        width: 6,
      },
      {
        type: "dateinput",
        value: "",
        label: "To Date",
        placeholder: "Select Date",
        required: true,
        width: 6,
      },
      {
        type: "dropdown",
          value: "",
          label: "Bank Name",
          placeholder: "Please Select a value",
          required: true,
          width: 6,
          options: optionsValue

      }
    ])

  }


  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [listData, setListData] = useState([]);
  let interval;

  const onSubmit = (e) => {
    let tempData = Object.assign({}, ...e);
    if (tempData["To Date"] >= tempData["From Date"]) {
      let apiQueryParams = `fromDate=${moment(tempData?.["From Date"]).format(
        dateFormats?.dmy
      )}&toDate=${moment(tempData?.["To Date"]).format(
        dateFormats?.dmy
      )}&user_name=${loginState?.decodedData?.user_name || ""}&bank_name=${tempData['Bank Name']}`;

      setLoading(true);
      apiCall
        .get(reportConstants?.downloadReversalFile + apiQueryParams)
        .then(async (res) => {
          if (res?.data?.status === 200) {
            tableAPI();
            if (res?.data?.response) {
              const link = document.createElement("a");
              link.href = res?.data?.response;
              link.click();
              toast.success(commonVariables?.FileDownloadSuccess, {
                toastId: toastIds?.FileDownloadSucess,
              });
            } else {
              toast.success(res?.data?.message || errMessage?.noDataFound, {
                toastId: toastIds?.DataError,
              });
            }
          } else {
            toast.error(res?.data?.message || errMessage?.noDataFound, {
              toastId: toastIds?.DataError,
            });
          }
        })
        .catch((er) => {
          toast.error(er?.message || errMessage?.somethingWentWrong, {
            toastId: toastIds?.DataError,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
        toastId: toastIds?.DataError,
      });
    }
  };
  const downloadbtn = (data) => {
    return (
      <div>
        <button
          disabled={!data}
          onClick={() => fetchBlockFile(data)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex align-items-center justify-content-center"
        >
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    );
  };
  const fetchBlockFile = (key) => {
    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, {
            toastId: toastIds?.FileDownloadSucess,
          });
        }
      })
      .catch((error) => {
        console.log("Error", error);
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };
  const tableAPI = () => {
    setAutoRefresh(true);
    apiCall
      .post(apiConstants?.fetchAllData, {
        tablename: tableNamePaymentConstants?.reportStatus,
        whereQuery: {
          created_by: loginState?.decodedData?.user_name || "",
          module_type: "PAYMENT",
          sub_module_type: "PAY_ReversalStatus_API_Based",
          metadata: {
            report_type: "Reversal_Status_API_Based",
          },
        },
        attributes: reversalApiBasedAttributes,
        order: [["id", "desc"]],
      })
      .then(async (res) => {
        if (res?.data?.status == 200) {
          let result = res?.data?.response;
          result = result.map((item, index) => {
            let newItem = {
              idData: index + 1,
              date: `${
                moment(item?.metadata?.StartDate, "YYYY-MM-DD").format(
                  "DD-MM-YYYY"
                ) || ""
              } to ${
                moment(item?.metadata?.EndDate, "YYYY-MM-DD").format(
                  "DD-MM-YYYY"
                ) || ""
              }`,
              moduletype: item?.module_type || "",
              submoduletype: item?.sub_module_type || "",
              rpname: item?.metadata?.reportName || "-",
              createdby: item?.created_by || "-",
              status: item?.status || "",
              action: downloadbtn(item?.s3_path),
            };
            return newItem;
          });
          let slice20 = result.slice(0, 20);
          if (slice20?.length > 0) {
            setListData([...slice20]);
          }
        } else {
          setListData([]);
          toast.error(res?.data?.message, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((er) => {
        setListData([]);
        toast.error(er?.message, { toastId: toastIds?.DataError });
      })
      .finally(() => {
        setAutoRefresh(false);
      });
  };
  // useEffect(() => {
  //   if (autoRefresh) {
  //     // interval = setInterval(myFunction, 15000);
  //     tableAPI();
  //     interval = setInterval(() => {
  //       tableAPI();
  //     }, 15000);
  //   } else {
  //     clearInterval(interval);
  //   }

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [autoRefresh]);
  useEffect(() => {
    tableAPI();
  }, []);




  const getSponsorPayment = () => {
    setLoading(true);
    let paramObj = {
      // offset: 0,
      // limit: 10,
      tablename: tableNamePaymentConstants?.sponsorBankPaymentTypeMaster,
      attributes: [attributePaymentConstants?.sponsorBankPaymentTypeMaster],
    };
    apiCall
      .post(apiConstants?.findData, paramObj)
      .then(async (res) => {
        let result = res?.data?.response;
        //result.forEach(item => console.log(item.bank_name));
       let resultData = result.filter( item => item.bank_name === "HDFC DIGI CFAB" || item.bank_name === "ICICI BANK");
       resultData = resultData.map((item) => {
          let newItem = {
            value: item.bank_name,
            label: item.bank_name,
          };
          return newItem;
        });
        setOptionsValue(resultData);
      })
      .catch((er) => {
        console.log("errr =>>>>", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };



  return (
    <>
      <div>
        {loading ? (
          <div className="loaderDiv">
            <SystemXLoader />
          </div>
        ) : (
          <>
            <p className="reversalStatus_reversalStatusTxt px-4 mt-4 pt-2">
              <b>Reversal Status - API Based</b>
            </p>
            <div className="disbursementReport_inputContainer mx-4 p-4">
              <DynamicForm
                formValues={formValues}
                onSubmit={(e) => onSubmit(e)}
              />
            </div>
          </>
        )}
        <div className="mx-4 pb-4">
          {listData.length > 0 && (
            <>
              <div className="pt-2 toggle-wrapper">
                <button
                  onClick={() => tableAPI()}
                  disabled={autoRefresh}
                  className="customRefreshButton mt-1"
                >
                  Refresh
                </button>
                {/* <div className="toggle-switch">
                  <input
                    className="toggle-input"
                    id="toggle"
                    checked={autoRefresh}
                    type="checkbox"
                    onClick={() => setAutoRefresh(!autoRefresh)}
                  />
                  <label className="toggle-label" for="toggle"></label>
                </div>
                <label className="label-name">Auto Refresh</label> */}
              </div>
              <CustomTable
                cols={[
                  {
                    id: "idData",
                    title: "ID",
                    classStyle: {},
                  },
                  {
                    id: "date",
                    title: "Date Range",
                    classStyle: {},
                  },
                  // {
                  //   id: "moduletype",
                  //   title: "Module Type",
                  //   classStyle: {},
                  // },
                  // {
                  //   id: "submoduletype",
                  //   title: "Sub Module Type",
                  //   classStyle: {},
                  // },
                  {
                    id: "rpname",
                    title: "Report Name",
                    classStyle: {},
                  },
                  {
                    id: "createdby",
                    title: "Created By",
                    classStyle: {},
                  },
                  {
                    id: "status",
                    title: "Status",
                    classStyle: {},
                  },
                  {
                    id: "action",
                    title: "Action",
                    classStyle: {},
                  },
                ]}
                data={listData}
                loading={loading}
              />
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Reversalapibased;
