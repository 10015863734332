import React, { useState } from "react";
import "./HoldPayment.scss";
import Payment from "../../../component/Payment/Payment";
import CustomTable from "../../../component/customtable/CustomTable";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  errMessage,
  paymentType,
  toastIds,
  updateStatusApi,
} from "../../../constants/Common/apiConstants";
import moment from "moment";
import { chain, difference, filter as _filter } from "lodash";
import {
  dateFormats,
  dummyList,
  paymentDropdownValues,
} from "../../../constants/Common/commonConstants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { errorMessages } from "../../../constants/Common/errorConstants";

const HoldPayment = () => {
  const [loading, setLoading] = useState(false);
  const [listdata, setListdata] = useState([]);
  const [checkedData, setCheckedData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(true);
 
  const [documentTableDummy, setDocumentTableDummy] = useState([
    ...dummyList.map((value, index) => {
      value.id = index + 1;
      return value;
    }),
  ]);
  const [pageNumber, setPageNumber] = useState(0);
  let LoginState = useSelector((state) => state.login);

  const handleCheckAll = (checkAll) => {
    if (checkAll) {
      setCheckedData(
        chain(listdata)
          .map((e) => (e.disabled ? undefined : e || e))
          .filter()
          .value()
      );
    } else {
      setCheckedData([]);
    }
  };
  const checkboxFormatter = (row, idx) => (
    <div className="checkBoxForm">
      <input
        class="form-check-input"
        type="checkbox"
        className="checkboxTablerow"
        disabled={row.disabled}
        onChange={() => {
          if (checkedData.includes(row || row)) {
            setCheckedData(checkedData.filter((e) => e !== (row || row)));
          } else {
            setCheckedData([...checkedData, row || row]);
          }
        }}
        checked={checkedData.includes(row || row)}
        id="flexCheckDefault"
      ></input>
    </div>
  );
  const updateStatus = (type) => {
    if (checkedData?.length) {
      let userData = LoginState?.decodedData;
      let apiBody = {
        ids: checkedData?.map((val) => {
          return val.id;
        }),
        case_status: type, //hold, regular ,exception
        user_name: userData?.user_name || "",
        user_code: userData?.user_code || "",
      };
      setLoading(true);
      apiCall
        .post(updateStatusApi, apiBody)
        .then((res) => {
          toast.success(errMessage?.PaymentHoldSuccessful, { toastId: toastIds?.PaymentHoldedSuccessfully });
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          listApi(null, true);
          setLoading(false);
        });
    } else {
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });
    }
  };
  const updateisApiCallState = (newState) => {
    setIsApiCall(newState);
  };
  const Table = () => {
    return (
      <div
        className="RegularPayment_tableAlignment"
        style={listdata?.length <= 0 ? { display: "none" } : {}}
      >
        <CustomTable
          cols={[
            {
              id: "id",
              checkbox: true,
              allChecked: listdata.length
                ? difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length === 0
                : false,
              handleCheckboxAction: () =>
                handleCheckAll(
                  difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length !== 0
                ),
              formatter: checkboxFormatter,
            },
            {
              id: "srNo",
              title: "#",
            },
            {
              id: "loanAccNo",
              title: "Loan Account No",
            },
            {
              id: "productDesc",
              title: "Product Description",
            },
            {
              id: "branch",
              title: "Branch Name",
            },
            {
              id: "journalNo",
              title: "Journal No / Payment Ref No",
            },
            {
              id: "disAmt",
              title: "Disbursement Amount",
            },
            {
              id: "cheque/dd no",
              title: "Cheque / DD No",
            },
            {
              id: "Cheque_DD_Date",
              title: "Cheque DD Date",
            },
            {
              id: "transactionDate",
              title: "Transaction Date",
            },
            {
              id: "applicantName",
              title: "Applicant Name",
            },
            {
              id: "payTo",
              title: "Pay to",
            },
            {
              id: "txnIMBSName",
              title: "Txn IMPS Bene Name",
            },
            {
              id: "beneNameScore",
              title: "Bene name match score",
            },
            {
              id: "status",
              title: "Status",
            },
            {
              id: "beneBankName",
              title: "Bene Bank Name",
            },
            {
              id: "Bene_Bank_Acct",
              title: "Bene Bank Account",
            },
            {
              id: "ifscCode",
              title: "IFSC Code",
            },

            {
              id: "paymentMode",
              title: "Payment Mode",
            },
            {
              id: "businessId",
              title: "Business ID",
            },
            {
              id: "webTop",
              title: "Webtop",
            },
            {
              id: "caseStatus",
              title: "Case Status",
            },
            {
              id: "remark",
              title: "Remark",
            },
          ]}
          data={listdata}
          count={5}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          holdFunction={() => updateStatus("hold")}
          loading={loading}
          isHoldPayment
          isApiCall={isApiCall}
          updateisApiCallState={updateisApiCallState}
        />
      </div>
    );
  };

  const listApi = (value, showErr) => {
    let temp = value
      ? (value - 1) * 10
      : pageNumber > 0
      ? (pageNumber - 1) * 10
      : 0;
    setLoading(true);
    let DataTmp = JSON.parse(localStorage.getItem(paymentDropdownValues));
    let parsedData = Object.assign({}, ...DataTmp);
    let paramObj = {
      offset: temp || 0,
      limit: 500,
      type: paymentType?.regular,
      company_code: parsedData["Company"] || "",
      sponsor_bank: parsedData["Sponsor Payment Bank"] || "",
      pay_to: parsedData["Vendor Type"]?.toLowerCase() || "",
      scheme_code: "",
    };
    apiCall
      .post(apiConstants?.fetchPayment, paramObj)
      .then((res) => {
        if (res.status === 200) {
          let result = res?.data?.response?.result;
          setIsApiCall(true);
          if (result?.length) {
            result = result.map((item, index) => {
              let newItem = {
                srNo: index + 1,
                loanAccNo: item?.loan_no || "-",
                id: item?.id || "",
                productDesc: item?.product_description || "-",
                branch: item?.branch_name || "-",
                journalNo: item?.fund_reference_id || "-",
                disAmt: item?.disbursment_amount || "-",
                "cheque/dd no": item?.cheque_dd_no || "-",
                transactionDate:
                  moment(item.pay_date).format(dateFormats?.dmyHms) || "-",
                applicantName: item?.applicant_name || "-",
                payTo: item?.pay_to || "-",
                txnIMBSName: item?.txn_imps_bene_name || "-",
                Cheque_DD_Date:
                  moment(item.cheque_dd_date).format(dateFormats?.dmyHms) ||
                  "-",
                Bene_Bank_Acct: item?.bene_bank_acct || "-",
                beneNameScore: item?.bene_name_match_score || "-",
                status: item?.acct_validation_status || '-',
                beneBankName: item?.bene_name_match_score || "-",
                ifscCode: item?.beneficiary_bank_ifsc || "-",
                paymentMode: item?.transaction_type || "-",
                businessId: item?.business_id || "-",
                webTop: item?.webtop_id || "-",
                caseStatus: item?.case_status || "-",
                remark: item?.remark || "-",
              };
              return newItem;
            });
          } else {
            setListdata([]);
            if(!showErr){
            toast.error(res.data.message || errorMessages.SOMETHING_WRONG, { toastId: toastIds?.DataError });
            }
          }
          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        if(!showErr){
        toast.error(er.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
        setListdata([]);
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const changePage = (value) => {
    setPageNumber(value - 1);
  };

  const setView = () => {
    setTimeout(() => {
      listApi();
    }, 400);
  };
  return (
    <>
      <div className="mt-4 pt-2 mx-3">
        <p className="HoldPayment_holdPaymentTxt ">
          <b>Hold Payment</b>
        </p>
        <div className="container-fluid ">
          <div className="row regular-payment-wrapper-row mb-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
              <Payment setView={() => setView()} isHoldPayment />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0">
              <div className="HoldPayment_max-widthTable RegularPayment_max-widthTable">
                {loading ? (
                  <div className="text-center spinnerDiv">
                    <SystemXLoader />
                    <p>
                      <b>Loading...</b>
                    </p>
                  </div>
                ) : (
                  <Table />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HoldPayment;
