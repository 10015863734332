import React, { useCallback, useEffect, useRef, useState } from "react";
import { Dropdown, Table } from "react-bootstrap";

import refreshIcon from "../../Images/refresh-icon.svg";
import cx from "classnames";
// import Paginations from "./Paginations";
// import TableTab from "./TableTab";
import { isEmpty } from "lodash";
import Pagination from "./Paginations";
import "./pagination.scss";
import SystemXLoader from "../common/Loader/SystemXLoader";
import { getClassName } from "../../utils/utils";
let scrollValueTop = 0;
let scrollValueLeft = 0;
export default function CustomTable({
  // tabs = [],
  activeTab,
  onTabClick,
  currentTabName,
  activePage,
  totalData,
  cols = [],
  data = [],
  count = 0,
  setPagination,
  loading = false,
  error = null,
  renderMenuItem,
  setHeaderValue,
  ...props
}) {
  const [pageCount, setCount] = useState(0);
  const [countArr, setCountArr] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    let temp = [];
    let abc = count;
    while (abc > 0) {
      temp.push(abc.toString());
      abc--;
    }
    setCountArr([...temp]);
    setCount(Math.ceil(count / 10));
    return () => {};
  }, [count]);

  const storeScrollValue = () => {
    scrollValueTop = tableRef.current.scrollTop;
    scrollValueLeft = tableRef.current.scrollLeft;
  };

  useEffect(() => {
    if (props?.isApiCall === true) {
      scrollValueTop = 0;
      scrollValueLeft = 0;
      tableRef.current.scrollTop = 0;
      props?.updateisApiCallState(false);
    }
  }, [props?.isApiCall]);

  useEffect(() => {
    tableRef.current.scrollTop = scrollValueTop;
    tableRef.current.scrollLeft = scrollValueLeft;
  }, [scrollValueTop, scrollValueLeft]);

  const renderSearchDropDown = useCallback(
    (placeholder, options = [], text, type, fn, search, index) => {
      if (isEmpty(options)) {
        if (search)
          return (
                <input
                  value={text}
                  icon="search"
                  placeholder={placeholder}
                  // clearable
                  className={cx("headerInput", {
                    headerSearchClient: index === 0,
                  })}
                  onChange={(e) => fn(e.target.value, type)}
                />
          );
        return placeholder;
      }
      return (
        <Dropdown
          className="ApprovalDropdown"
          placeholder={placeholder}
          options={options}
          value={text}
          search
          selection
          clearable
          selectOnBlur={false}
          onChange={(e, { value }) => {
            fn(value, type);
          }}
        />
      );
    },
    []
  );
  const renderHeader = () => {
    return cols.map((item, index) => {
      if (!item?.disabled) {
        return item.checkbox ? (
          <th className="fixed-row table-with-drop-down table-header">
            <div className="checkBoxForm">
              <input
                class="form-check-input"
                type="checkbox"
                className="checkboxTablehead"
                onChange={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  storeScrollValue();
                  item.handleCheckboxAction();
                }}
                checked={item.allChecked}
                id="flexCheckDefault"
              ></input>
            </div>
          </th>
        ) : (
          <th
            className={cx("table-with-drop-down table-header", {
              "fixed-row": index === 0 || (item.fixed && item.fixed),
            })}
            key={`table_header_title__${index}`}
            // onClick={()=>setHeaderValue(item.id)}
          >
            {renderSearchDropDown(
              item.title,
              item.options,
              item.text,
              item.type,
              item.onSelect,
              item.search,
              index
            )}
          </th>
        );
      }
    });
  };

  const renderBody = () => {
    return data.map((row, rowIndex) => {
      if (row) {
        return (
          <tr key={`row_${rowIndex}`}>
            {cols.map((col, colIndex) => {
              if (!col?.disabled) {
                return col.formatter ? (
                  <td
                    className={cx(`table-body ${col.id}`, {
                      "fixed-row": colIndex === 0 || (col.fixed && col.fixed),
                    })}
                    key={`col_${rowIndex}_${colIndex}`}
                    onClick={() => storeScrollValue()}
                  >
                    {col.formatter(row, rowIndex, col.id)}
                  </td>
                ) : (
                  <td
                    className={cx(`table-body ${col.id}`, {
                      "fixed-row": colIndex === 0 || (col.fixed && col.fixed),
                    }, `${row.customCondition && getClassName(col, 14, row, 'FinalStatusFromBank', 'Rejected by Banking Operation')}`)}
                    key={`col_${rowIndex}_${colIndex}`}
                  >
                    {row[`${col.date}`]}
                    {row[`${col.id}`]}
                  </td>
                );
              }
            })}
          </tr>
        );
      }
    });
  };

  // const renderTabs = () => {
  // 	return tabs.map((tab, tabIndex) => {
  // 		return (
  // 			<Grid.Column
  // 				mobile={8}
  // 				tablet={8}
  // 				computer={2}
  // 				key={`tab_name${tab.title}_${tabIndex}`}
  // 			>
  // 				<TableTab
  // 					loading={props.tabLoading}
  // 					title={tab.title}
  // 					onClickfn={onTabClick}
  // 					image={tab.image}
  // 					activeImage={tab.active_image}
  // 					value={tab.value}
  // 					activeId={activeTab}
  // 					tabId={tab.tabId}
  // 					cKey={`tab_Tab${tab.title}_${tabIndex}`}
  // 				/>
  // 			</Grid.Column>
  // 		);
  // 	});
  // };
  return (
    <>
      <div className="body-item-wrapper">
        <div className="graph-div">
          <div className="mainDiv custom-table">
            {/* {tabs.length > 0 && <Grid key="tab_grid">{renderTabs()}</Grid>} */}
            <div
              className={"custom-table"}
              style={{
                overflowY: "auto",
                width: "100%",
                maxWidth: "100%",
                marginTop: 10,
              }}
            >
              <div className="verticalScrollWrapper" ref={tableRef}>
                <Table className="table-bordered">
                  <thead key={"table_header"}>
                    <tr key={"table_row"}>{renderHeader()}</tr>
                  </thead>
                  <tbody className="tableHover">{renderBody()}</tbody>
                </Table>
              </div>
              {data.length <= 0 && error && loading === false && (
                <>
                  <div className="no-record-found text-center p-4">
                    No Record Found
                  </div>
                </>
              )}
              {data?.length <= 0 && !loading && (
                <div
                  className="no-record-found text-center p-4"
                  style={{ textAlign: "center" }}
                >
                  No Record Found
                </div>
              )}
              {loading && isEmpty(data) && (
                <>
                  <div className="no-record-found text-center p-4">
                    <SystemXLoader />
                    Loading...
                  </div>
                </>
                // <div className="loader-backgroung">
                // 	<div className="loading-spinner"></div>
                // </div>
              )}
              <div className="d-flex flex-row custom-table_btn-position">
                {props?.isCaseDetails === "CaseDetails" && (
                  <>
                    {/* <button className="custom-table_green-btn m-3 pt-2 pb-2 px-2">
                      Mark As Success
                    </button> */}
                    <button
                      className="custom-table_red-btn m-3 pt-2 pb-2 px-2"
                      disabled={props?.updateLoading}
                      onClick={() => (props?.onFailed ? props?.onFailed() : "")}
                    >
                      {props?.updateLoading ? "Loading..." : "Mark As Failed"}
                    </button>
                  </>
                )}
                {props?.isCaseDetails === "MoveToException" && (
                  <>
                    <button
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                      disabled={props?.updateLoading}
                      onClick={() =>
                        props?.onMoveToException
                          ? props?.onMoveToException()
                          : ""
                      }
                    >
                      {props?.updateLoading
                        ? "Loading..."
                        : "Move To Exception"}
                    </button>
                  </>
                )}
                {props?.isExceptionPayment && (
                  <>
                    <button
                      onClick={() =>
                        props?.holdFunction ? props?.holdFunction() : ""
                      }
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                    >
                      Move To Hold
                    </button>
                    <button
                      onClick={() =>
                        props?.unholdFunction ? props?.unholdFunction() : ""
                      }
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                    >
                      Move To Regular
                    </button>
                  </>
                )}
                {props?.isUnholdPayment && (
                  <>
                    <button
                      onClick={() =>
                        props?.unholdFunction ? props?.unholdFunction() : ""
                      }
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                    >
                      Unhold Payment
                    </button>
                    <button
                      onClick={() =>
                        props?.rejectFunction ? props?.rejectFunction() : ""
                      }
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                    >
                      Reject
                    </button>
                  </>
                )}
                {props?.isRegularPayment && (
                  <>
                    <div className="regularPaymentButtonDiv">
                      <div>
                        <button
                          disabled={props?.updateLoading || props?.isDisabled}
                          onClick={() =>
                            props?.releasePayment ? props?.releasePayment() : ""
                          }
                          className={`custom-table_green-btn my-3 ml-3 mr-5 pt-2 pb-2 px-2 ${props?.updateLoading || props?.isDisabled ? 'disabled' : ''}`}
                        >
                          Payment via File
                        </button>
                        <button
                          disabled={props?.updateLoading}
                          onClick={() =>
                            props?.releaseApi ? props?.releaseApi() : ""
                          }
                          className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                        >
                          Release Via API
                        </button>
                      </div>
                      <div>
                        <button
                          disabled={props?.updateLoading}
                          onClick={() =>
                            props?.holdFunction ? props?.holdFunction() : ""
                          }
                          className="custom-table_yellow-btn m-3 pt-2 pb-2 px-2"
                        >
                          Hold Payment
                        </button>
                        <button
                          disabled={props?.updateLoading}
                          onClick={() =>
                            props?.rejectFunction ? props?.rejectFunction() : ""
                          }
                          className="custom-table_red-btn m-3 pt-2 pb-2 px-2"
                        >
                          Reject Payment
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {props?.isRegularPaymentMaker && (
                  <>
                    <div className="regularPaymentButtonDiv">
                      <div>
                        <button
                          disabled={props?.updateLoading}
                          onClick={() =>
                            props?.releaseApi ? props?.releaseApi() : ""
                          }
                          className="custom-table_green-btn my-3 ml-3 mr-5 pt-2 pb-2 px-2"
                        >
                          Release To Checker
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {props?.isHoldPayment && (
                  <>
                    <button
                      onClick={() =>
                        props?.holdFunction ? props?.holdFunction() : ""
                      }
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                    >
                      Hold Payment
                    </button>
                  </>
                )}
                {props?.isCaseDetailsApproval && !data?.length <= 0 && (
                  <>
                    {/* <button
                      className="custom-table_icon-btn"
                      onClick={() =>
                        props?.refreshFunction ? props?.refreshFunction() : ""
                      }
                    >
                      <img src={refreshIcon} alt="refreshIcon"></img>
                    </button> */}
                    <button
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                      disabled={props?.updateLoading}
                      onClick={() =>
                        props?.approveFunction ? props?.approveFunction() : ""
                      }
                    >
                      Approve
                    </button>
                    <button
                      disabled={props?.updateLoading}
                      className="custom-table_red-btn m-3 pt-2 pb-2 px-2"
                      onClick={() =>
                        props?.rejectFunction ? props?.rejectFunction() : ""
                      }
                    >
                      Reject
                    </button>
                  </>
                )}
                {props?.isException && (
                  <>
                    <button
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                      disabled={props?.updateLoading}
                      onClick={() =>
                        props?.releasePaytmApi ? props?.releasePaytmApi() : ""
                      }>
                      Release Payment
                    </button>
                  </>
                )}
                {props?.isCallback && (
                  <>
                    <button
                      className="custom-table_green-btn m-3 pt-2 pb-2 px-2"
                      disabled={props?.updateLoading}
                      onClick={() =>
                        props?.releaseCallbackApi ? props?.releaseCallbackApi() : ""
                      }>
                      Release Callback
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {count > 10 && (
        <div style={{ marginTop: "20px", marginRight: "20px" }}>
          <Pagination
            pageSize={Math.ceil(count / 10)}
            activePage={activePage}
            items={countArr}
            onChangePage={(e) => {
              setPagination(e);
            }}
          />
          {/* <Paginations
						totalPages={pageCount}
						activePage={activePage}
						setActivePage={(page) => setPagination(page)}
					/> */}
        </div>
      )}
    </>
  );
}
