import React, { useState } from "react";
import "./Exception.scss";
import PartnerPayments from "../../../component/PartnerPayments/PartnerPayments";
import CustomTable from "../../../component/customtable/CustomTable";
import apiCall from "../../../utils/apiFunction/apiCall";
import {
  apiConstants,
  errMessage,
  paymentType,
  toastIds,
  updateStatusApi,
} from "../../../constants/Common/apiConstants";
import moment from "moment";
import { chain, difference, filter as _filter } from "lodash";
import {
  dateFormats,
  dummyList,
  paytmPaymentDropdownValues,
} from "../../../constants/Common/commonConstants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import { errorMessages } from "../../../constants/Common/errorConstants";
import CustomModal from "../../../component/modal/modal";

const Exception = () => {
  const [loading, setLoading] = useState(false);
  const [listdata, setListdata] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [checkedData, setCheckedData] = useState([]);
  const [isApiCall, setIsApiCall] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});

 
  const [documentTableDummy, setDocumentTableDummy] = useState([
    ...dummyList.map((value, index) => {
      value.id = index + 1;
      return value;
    }),
  ]);
  const [pageNumber, setPageNumber] = useState(0);
  let LoginState = useSelector((state) => state.login);

  const handleCheckAll = (checkAll) => {
    if (checkAll) {
      setCheckedData(
        chain(listdata)
          .map((e) => (e.disabled ? undefined : e || e))
          .filter()
          .value()
      );
    } else {
      setCheckedData([]);
    }
  };
  // const checkboxFormatter = (row, idx) => (
  //   <div className="checkBoxForm">
  //     <input
  //       class="form-check-input"
  //       type="checkbox"
  //       className="checkboxTablerow"
  //       disabled={row.disabled}
  //       onChange={() => {
  //         if (checkedData.includes(row || row)) {
  //           setCheckedData(checkedData.filter((e) => e !== (row || row)));
  //         } else {
  //           setCheckedData([...checkedData, row || row]);
  //         }
  //       }}
  //       checked={checkedData.includes(row || row)}
  //       id="flexCheckDefault"
  //     ></input>
  //   </div>
  // );


  const checkboxFormatter = (row, idx) => (
    <div className="checkBoxForm">
      <input
        className=" checkboxTablerow"
        type="checkbox"
        disabled={row.disabled}
        onChange={() => {
          console.log("Checkbox clicked:", row);
          setCheckedData((prevCheckedData) => {
            if (prevCheckedData.includes(row)) {
              return prevCheckedData.filter(
                (item) => item?.loanAccNo !== row?.loanAccNo
              );
            } else {
              return [...prevCheckedData, row];
            }
          });
        }}
        checked={checkedData.includes(row)}
        id="flexCheckDefault"
      />
    </div>
  );

  const updateStatus = (type) => {
    if (checkedData?.length) {
      let userData = LoginState?.decodedData;
      let apiBody = {
        ids: checkedData?.map((val) => {
          return val.id;
        }),
        case_status: type, //hold, regular ,exception
        user_name: userData?.user_name || "",
        user_code: userData?.user_code || "",
      };
      setLoading(true);
      apiCall
        .post(updateStatusApi, apiBody)
        .then((res) => {
          toast.success(errMessage?.PaymentHoldSuccessful, { toastId: toastIds?.PaymentHoldedSuccessfully });
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => {
          listApi(null, true);
          setLoading(false);
        });
    } else {
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });
    }
  };

  const releasePaytmApi = () => {
    if(checkedData?.length){
      let userData = LoginState?.decodedData;
       let DataTmp = JSON.parse(localStorage.getItem(paytmPaymentDropdownValues));
       let parsedData = Object.assign({},...DataTmp);
      let apiBody = {
        ids: checkedData?.map((val) => {
          return val.id;
        }),
      };
      setLoading(true);
      apiCall
        .post(apiConstants?.ApiPaymentReportPaytmPayment,apiBody)
        .then((res) =>{
          if(res.status === 200){
            console.log("res",res.status);
          let Success = res?.data?.count?.sentForPaymentCount;
          let Exception = res?.data?.count?.exceptionCount;
            setModalData({ "Sent for payment": Success, Exception: Exception });
            setShowModal(true);
          toast.success(
            `Payment done for ${checkedData?.length} cases successfully`,
            {
              toastId: toastIds?.recordSentSuccessfully,
            }
          );
        }
        else{
          toast.error(res.data.message, {
            toastId: toastIds?.recordSentSuccessfully,
          });
        }

        })
        .catch((err) => {
          console.log("err>>>>>>", err);
          toast.error(err, {
            toastId: toastIds?.recordSentSuccessfully,
          });
        })
        .finally(() => {
          setCheckedData([]);
          listApi(null, true);
          setUpdateLoading(false);
        });
    }
    else{
      toast.error(errMessage?.SelectAValue, { toastId: toastIds?.selectVal });
    }
  }
  const updateisApiCallState = (newState) => {
    setIsApiCall(newState);
  };
  const Table = () => {
    return (
      <div
        className="RegularPayment_tableAlignment"
        style={listdata?.length <= 0 ? { display: "none" } : {}}
      >
        <CustomTable
          cols={[
            {
              id: "id",
              checkbox: true,
              allChecked: listdata.length
                ? difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length === 0
                : false,
              handleCheckboxAction: () =>
                handleCheckAll(
                  difference(
                    listdata.map((e) => e),
                    checkedData
                  ).length !== 0
                ),
              formatter: checkboxFormatter,
            },
            {
              id: "srNo",
              title: "#",
            },
            {
              id: "loanAccNo",
              title: "Loan Account No",
            },
            {
              id: "companyCode",
              title: "Company Code"
            },
            {
              id: "customerName",
              title: "Customer Name"
            },
            {
              id: "txnid",
              title: "Txn_Id"
            },
            {
              id: "txnStatusCode",
              title: "Txn_Status_Code"
            },
            {
              id:"txnStatusDesc",
              title: "Txn_Status_Desc"
            },
            {
              id:"txnStatusDescTc",
              title: "Txn_Status_Desc_Tc"
            },
            {
              id:"txnUtr",
              title: "Txn_Utr"
            },
            {
              id:"paytmStatusDesc",
              title:"Paytm_Status_Desc"
            }
      
          ]}
          data={listdata}
          count={5}
          setPagination={(page) => changePage(page)}
          activePage={pageNumber}
          //={() => updateStatus("hold")}
          releasePaytmApi={() => releasePaytmApi()}
          loading={loading}
          updateLoading={updateLoading}
          isException
          isApiCall={isApiCall}
          updateisApiCallState={updateisApiCallState}
        />
      </div>
    );
  };

  const listApi = (value, showErr) => {
    let temp = value
      ? (value - 1) * 10
      : pageNumber > 0
      ? (pageNumber - 1) * 10
      : 0;
    setLoading(true);
    let DataTmp = JSON.parse(localStorage.getItem(paytmPaymentDropdownValues));
    let parsedData = Object.assign({}, ...DataTmp);
    let paramObj = {
      offset: temp || 0,
      limit: 300,
      company_code: parsedData["Company"] || "",
      source_system_name: parsedData["Source System"]
      
    };
    apiCall
      .post(apiConstants?.fetchPaytmPayment, paramObj)
      .then((res) => {
        console.log(res,"res>????????????????????????");
        if (res.status === 200) {
          let result = res.data.response
          console.log("result>>>>>>>>>>>>><<<<<<<<<<<<<<<<<<<<<<",result);
          setIsApiCall(true);
          if (result?.length) {
            result = result.map((item, index) => {
              let newItem = {
                srNo: index + 1,
                loanAccNo: item?.loan_number || "-",
                id: item?.id || "",
                companyCode: item?.company_code || "",
                customerName: item?.customer_name || "",
                txnid: item?.txn_id || "",
                txnStatusCode: item?.txn_status_code || "",
                txnStatusDesc: item?.txn_status_desc || "",
                txnStatusDescTc: item?.txn_status_desc_tc || "",
                txnUtr: item?.txn_utr || "",
                paytmStatusDesc: item?.paytm_status_desc || ""
              };
              return newItem;
            });
          } else {
            setListdata([]);
            if(!showErr){
            toast.error(res.data.message || errorMessages.SOMETHING_WRONG, { toastId: toastIds?.DataError });
            }
          }

          if (result?.length > 0) {
            setListdata([...result]);
          }
        }
      })
      .catch((er) => {
        if(!showErr){
        toast.error(er.message || errorMessages.SOMETHING_WRONG, {
          toastId: toastIds?.DataError,
        });
      }
        setListdata([]);
        console.log("err", er);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const changePage = (value) => {
    setPageNumber(value - 1);
  };

  const setView = () => {
    console.log("list d a ta >>>>>>>>>>>>>> ", listdata);
    setTimeout(() => {
      listApi();
    }, 400);
  };
  return (
    <>
{modalData && (
  <CustomModal
  heading="Payment Status"
  data={modalData}
  show={showModal}
  onHide={() => setShowModal(false)}
/>
)}
  
      <div className="mt-4 pt-2 mx-3">
        <p className="HoldPayment_holdPaymentTxt ">
          <b>Paytm Exception</b>
        </p>
        <div className="container-fluid ">
          <div className="row regular-payment-wrapper-row mb-3">
            <div className="col-12 col-sm-12 col-md-12 col-lg-3 p-0">
              <PartnerPayments setView={() => setView()} isException />
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-9 p-0">
              <div className="HoldPayment_max-widthTable RegularPayment_max-widthTable">
                {loading ? (
                  <div className="text-center spinnerDiv">
                    <SystemXLoader />
                    <p>
                      <b>Loading...</b>
                    </p>
                  </div>
                ) : (
                  <Table />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Exception;
