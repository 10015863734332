import React from 'react'
import { Modal, Button } from "react-bootstrap";
import "./approveModal.scss"
const ApproveConfirmation = ({ showModal, hideModal, confirmModal, id, type, message }) => {
    return (
        <Modal centered show={showModal} onHide={hideModal}>
        <Modal.Header className='custom-modal-header' closeButton>
          <Modal.Title className='custom-modal-title' >Approve Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="alert alert">{message}</div></Modal.Body>
        <Modal.Footer>
          <Button className='custom-modal-button' onClick={() => confirmModal(type, id) }>
            Yes
          </Button>
          <div className='bufferMargin'></div>
          <Button variant="default" onClick={hideModal}>
            No
          </Button>
        </Modal.Footer>
      </Modal>
    )
}

export default ApproveConfirmation;