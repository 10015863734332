import React, { useState, useEffect } from "react";
import './toggle.scss';
import Button from "../../../../component/common/Button/button";
import FormLabel from '../../../../component/common/formLabel/formLabel'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { array, object, string } from "yup";
import FormikSelect from "../../../../component/common/formikSelect/formikSelect";
import apiCall from '../../../../utils/apiFunction/apiCall'
import { useSelector } from "react-redux";

import {
  apiConstants,
  FOMRS,
  isActiveDropdown,
  STATUS,
  tableNamePaymentConstants,
  successMsg, toastIds
} from "../../../../constants/Common/apiConstants";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATH } from "../../../../constants/Routes";
import { YupMessages } from "../../../../constants/Common/commonConstants";
import { toast } from "react-toastify";
// import CustomModal from "../modal/modal";
import ResponseModal from "../../../../component/responseModal/responseModal";

let validSchema = object({
  // id: string().required('Id is required'),
  auth_mode: string().required('Authentication mode name is required'),
  isActive: string().required('IsActive status is required')
});

const ToggleAuthModeActiveStatus = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  let LoginState = useSelector((state) => state.login);
  
  const [isActive, setIsActive] = useState([
    { key: 'Select an option', value: ''},
    { key: 'Yes', value: '1' },
    { key: 'No', value: '0' },
  ])

  const formType = state?.type || FOMRS.ADD;

  const headingText =
    formType === FOMRS.ADD ? "Create Authentication Mode" : "Update Authentication Mode";

  const [formData, setFormData] = useState({
    // id: "",
    auth_mode: "",
    isActive: "",
  });
  const [loading, setLoading] = useState(false);
   
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState({});
  
  const FormSubmit = (data) => {
    // console.log('----------data', data)
    let query = {
      body: {
        is_active: data.isActive.toString() === "1" ? true : false
      },
      tablename: tableNamePaymentConstants?.AUTH_MODE_STATUS,
    };

    // console.log('----------->query', query)

    if (formType === FOMRS.EDIT) {
      query.whereQuery = {
        id: state?.data?.id,
      };
      apiCall.post(apiConstants?.updateUser, query).then((res) => {
        if (res?.status === STATUS.SUCCESS) {
          // console.log(res)
          navigate(ROUTE_PATH.AUTHENTICATION_MODE);
          toast.success(successMsg?.AuthModeUpdatedSuccessfully, toastIds?.FileUploadSuccess)
        } else if (res?.status === STATUS.BAD_REQUEST) {
          let responseForModal = res?.data?.message
            // console.log(responseForModal)
            setModalData(responseForModal)
            setShowModal(true)
        }
      });
    }

    if (formType === FOMRS.ADD) {

      const createQuery = {
        body: {
          id: Number(state?.latestId) + 1,
          auth_mode: data.auth_mode,
          is_active: data.isActive.toString() === "1" ? true : false
        },
        tablename: tableNamePaymentConstants?.AUTH_MODE_STATUS,
      }

      apiCall.post(apiConstants?.createUser, createQuery).then((res) => {
        if (res?.status === STATUS.SUCCESS) {
          let statusRes = res?.data?.status
          if (statusRes == 404) {
            let responseForModal = res?.data?.message
            // console.log(responseForModal)
            setModalData(responseForModal)
            setShowModal(true)
          } else {
            navigate(ROUTE_PATH.AUTHENTICATION_MODE);
            toast.success(successMsg?.AuthModeCreatedSuccessfully, toastIds?.FileUploadSuccess)
          }
        }
      });
    }
  };

  useEffect(() => {
    if (formType === FOMRS.EDIT) {
      setFormData({
        // id: state.data.id,
        auth_mode: state.data.auth_mode,
        isActive: state.data.is_active
      });

    }
  }, [loading]);

  return (
    <>
        {modalData && 
          <ResponseModal
            heading="Authentication mode status"
            data={modalData}
            show={showModal}
            onHide={() => setShowModal(false)}
            color='danger'
          />
          }
        <Formik
          initialValues={{
            // id: formData?.id,
            auth_mode: formData?.auth_mode,
            isActive: formData?.isActive,
          }}
          validationSchema={validSchema}
          enableReinitialize={true}
          onSubmit={(values) => {
            FormSubmit(values);
          }}
        >
        {({ values, setFieldValue }) => (
          <Form>
            <div id="createEnachVendor">
              <div className="row flexRow">
                <div
                  className="col blueFonts large_f"
                  onClick={() => navigate(ROUTE_PATH?.AUTHENTICATION_MODE)}
                >
                  {`Authentiation Mode >> `}
                  <span className="blackFonts">{headingText}</span>
                </div>
              </div>
              <div className="whiteBG">
                <div className="blueBG">
                  <span className="titleFont">{headingText}</span>
                </div>
                <div className="field">
                  <div className="row paddingTop">
                    {/* <div className="col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel value="id" className="required" />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="id"
                        disabled={formType === FOMRS.EDIT ? true : false}
                        placeholder="Id"></Field>
                      <div className="errorMessage">
                        <ErrorMessage sage name="id" />
                      </div>
                    </div> */}
                  </div>
                  <div className="row paddingTop">
                    <div className="col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel
                          value="Authentication mode"
                          className="required"
                        />
                      </div>
                      <Field
                        type="text"
                        className="InputField"
                        name="auth_mode"
                        disabled={formType === FOMRS.EDIT ? true : false}
                        placeholder="Authentication mode"></Field>
                      <div className="errorMessage">
                        <ErrorMessage name="auth_mode" />
                      </div>
                    </div>
                  </div>
                  
                  <div className="row paddingTop">
                    <div className="col-lg-12 medium_f_bold directionColumn">
                      <div>
                        <FormLabel value="is Active" className="required" />
                      </div>
                      <FormikSelect
                        label="select a topic"
                        name="isActive"
                        options={isActive}
                        value={values.isActive}
                      />
                      <div className="errorMessage">
                        <ErrorMessage name="isActive" />
                      </div>
                    </div>
                  </div>

                  
                  <div className="submitButton">
                    <div className="buttonSets">
                      <Button
                        role="secondary"
                        value="Cancel"
                        onClick={() => navigate(ROUTE_PATH?.AUTHENTICATION_MODE)}
                      />
                      <Button
                        role="primary"
                        value="Submit"
                        onClick={() => { }}
                        type="submit"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};
export default ToggleAuthModeActiveStatus;
