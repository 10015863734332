import React, { useEffect, useState } from "react";
import "./NewApiReports.scss";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import apiCall from "../../../utils/apiFunction/apiCall";
import { errorMessages } from "../../../constants/Common/errorConstants";
import { toast } from "react-toastify";
import {
  toastIds,
  STATUS,
  apiConstants
} from "../../../constants/Common/apiConstants";
import { Alert, Button } from "react-bootstrap";
import CustomTable from "../../../component/customtable/CustomTable";
import ScrollingText from "./ScrollingText";
import { buildQueryParamsString } from "../../../utils/utils";
import StatusTracker from "./StatusTracker";

const contractStatusSchema = {
  CompanyCode: "",
  ContractNo: "",
  Branch: "",
  FundReferenceID: "",
  DisbursementAmount: "",
  PayDate: "",
  PayTo: "",
  DisbursementFromLMSToSystemX: '',
  ProductDescription: "",
  DisbursementField4: "",
  Bucket: "",
  SystemXSendToSponsorBank: "",
  FinalStatusFromBank: "",
  UTR: "",
  UTRGeneratedDate: "",
  DateOfCaseHold: "",
  DateOfCaseRejected: "",
  ReasonOfCaseRejection: "",
  DisbursementUpdatedAt: ""
}



const ContractStatusTrackerViewer = () => {
  const [formValues, setFormValues] = useState([
    {
      type: "textbox",
      value: '',
      label: "Contract no.",
      placeholder: "Contract no.",
      required: true,
      expandInput: true,
      width: 6,
    },
    {
      type: "textbox",
      value: '',
      label: "Fund Reference ID",
      placeholder: "Fund Reference ID",
      width: 6,
      expandInput: true,
    },
  ]);
  const [tableShowData, setTableShowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [formData, setFormData] = useState();
  const [firstApiHit, setFirstHit] = useState(false)
  const [trackerData, setTrackerData] = useState({})
  const [showErrMsg, setShowErrMsg] = useState(false)

  const responseFormatter = (apiResponse) => {
    apiResponse.customCondition = apiResponse.FinalStatusFromBank === 'Rejected by Banking Operation' || apiResponse.Bucket === 'Rejected by Banking Ops' ? true : false
    apiResponse.viewBtn = (
      <>
        <button type="button" class="btn px-4 py-1 font-bold" style={{backgroundColor: '#dfdf23'}} onClick={() => {setTrackerData(apiResponse)}}>View</button>
      </>
    )
    Object.keys(contractStatusSchema).forEach(key => {
      if(!apiResponse[key]) apiResponse[key] = '—'
    })
  }

  const fetchContractDetailsWithStatus = async (queryString) => {
    setLoading(true)
    setTrackerData({})
    setShowErrMsg(false)
    let jsonResponse;
    try {
      const apiResponse = await apiCall.get(`${apiConstants.contractDetails}${queryString}`) 
  
      jsonResponse = apiResponse?.data

      if(jsonResponse.status !== STATUS.SUCCESS) {
        throw new Error(jsonResponse.message)
      }

      if(jsonResponse.response.data.length === 1) { 
        setTrackerData(jsonResponse.response.data[0])
      }

      jsonResponse.response.data.map(item => responseFormatter(item))

      setTableShowData(jsonResponse.response.data)
      
      
    } catch (error) {
      console.log(error)
      setTableShowData([])
      setTrackerData({})
      setShowErrMsg(true)
      
      // if(jsonResponse?.status === STATUS.NOT_FOUND) 
        
      // jsonResponse.message = 'Contract No. not found. Please connect with Disbursement Team'

      // toast.error(jsonResponse?.message || errorMessages.SOMETHING_WRONG, {
      //   toastId: toastIds?.DataError,
      // });
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = async (value, data) => {
    setFirstHit(true)
    const contractNo = data[0]['Contract no.']
    const fundRefId = data[1]['Fund Reference ID']
    const queryString = buildQueryParamsString({contractNo, fundRefId: fundRefId})
    await fetchContractDetailsWithStatus(queryString)
  };

  const changePage = (value) => {
    setPageNumber(value);
  };

  const Table = () => {
    const columns = [
      {
        id: "id",
        title: "Sr No.",
      },
      {
        id: "CompanyCode",
        title: "Company Code",
      },
      {
        id: "ContractNo",
        title: "Contract No.",
      },
      {
        id: "Branch",
        title: "Branch",
      },
      {
        id: "FundReferenceID",
        title: "Fund Reference ID",
      },
      {
        id: "DisbursementAmount",
        title: "Disbursement Amount",
      },
      {
        id: "PayDate",
        title: "LMS-Payment Date",
      },
      {
        id: "PayTo",
        title: "Pay To",
      },
      {
        id: "DisbursementFromLMSToSystemX",
        title: "Disbursement From LMS To SystemX",
      },
      {
        id: "ProductDescription",
        title: "Product Description",
      },
      {
        id: "DisbursementField4",
        title: "Disbursement Type",
      },
      {
        id: "Bucket",
        title: "Current Stage",
      },
      {
        id: "SystemXSendToSponsorBank",
        title: "SystemX Send To Sponsor Bank",
      },
      {
        id: "FinalStatusFromBank",
        title: "Final Payment Status",
      },
      {
        id: "UTR",
        title: "UTR",
      },
      {
        id: "UTRGeneratedDate",
        title: "UTR Generated Date",
      },
      {
        id: "DateOfCaseHold",
        title: "Date Of Case Hold",
      },
      {
        id: "DateOfCaseRejected",
        title: "Date Of Case Rejected",
      },
      {
        id: "ReasonOfCaseRejection",
        title: "Reason of Case Failed/Rejection",
      },
    ];

    if(tableShowData.length > 1) {
      columns.splice(1, 0, {       
        id: 'viewBtn',
        title: ''
      })
    }

    return (
      <div className="mx-4">
        <CustomTable
          cols={columns}
          data={tableShowData}
          // count={count}
          // activePage={pageNumber}
          loading={loading}
          // setPagination={(page) => changePage(page)}
        />
      </div>
    );
  };

  const ShowNotification = () => {
    return (
      <div className="mx-4 mt-2">
          <ScrollingText />
      </div>
    )
  }

  useEffect(() => {
    if(pageNumber) {
      onSubmit(pageNumber, formData);
    }
  }, [pageNumber]);

  return (
    <div>
      {loading && (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <p className="disbursementTable_disbursementTableTxt px-4 mt-4 pt-2">
        <b>Current Contract No. Status</b>
      </p>
      <div className="disbursementTable_inputContainer mx-4 p-4">
        <DynamicForm onSubmit={(data) => {setFormData(data); onSubmit(pageNumber, data);}} withClearBtn formValues={formValues} />
      </div>
      <ShowNotification />
      { 
        firstApiHit  &&
        <>
          <Table />
          {(Object.keys(trackerData).length !== 0) && <StatusTracker data={trackerData}/>}
        </>
      }
      {
        showErrMsg && 
        <div className="py-2 px-4">
          <Alert style={{backgroundColor: '#EF0107', color: 'white'}} onClose={() => setShowErrMsg(false)}>
            <div className="text-center">
              Contract No. not found. Please connect with the Branch Disbursement Team.
            </div>
          </Alert>
        </div>
      }
    </div>
  );
};

export default ContractStatusTrackerViewer;