import * as Yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import FormLabel from '../../../../common/formLabel/formLabel';
import { ROUTE_PATH } from '../../../../../constants/Routes';
import FormikSelect from '../../../../common/formikSelect/formikSelect';
import apiCall from '../../../../../utils/apiFunction/apiCall';
import {
	apiConstants,
	FOMRS,
	tableNamePaymentConstants,
	isActiveDropdown,
	isActiveDropdowns,
	successMsg
} from '../../../../../constants/Common/apiConstants';
import { useNavigate, useLocation } from 'react-router-dom';
import Button from '../../../../common/Button/button';
import React, { useState, useEffect } from 'react';
import '../createCollectionSponsorBank/createCollectionSponsorBank.scss';
import { YupMessages } from '../../../../../constants/Common/commonConstants';
import { toast } from 'react-toastify';

const validationSchema = Yup.object({
	bank_name: Yup.string().required(YupMessages?.NameRequired),
	bank_code: Yup.string().required("Bank Code is required"),
	mandate_type: Yup.string().required(YupMessages?.MandateTypeRequired),
	amount_to_pay: Yup.number().when('mandate_type', {
		is: (mandate_type) =>
			['ENACH', 'EMANDATE'].includes(mandate_type)
		,
		then: () => Yup.number().required('Amount To Pay is required').min(1, 'Number Should be greater than zero')
	}),
	f1_picklist_bank_name: Yup.string(),
	gl_code: Yup.string().when(['nupay_bank_id', 'mandate_type'], {
		is: (nupay_bank_id, mandate_type) =>
			((nupay_bank_id == undefined) && ['ENACH'].includes(mandate_type)),
		then: () => Yup.string().required('GL Code is required')
	}),
	bank_id: Yup.string().when('gl_code', {
		is: (gl_code) => {
			if (!(gl_code === undefined)) {
				return gl_code
			}
		},
		then: () => Yup.string().required('EMANDATE/ENACH Netbanking Code is required')
	}),
	debit_card_emandate_id: Yup.string().when('gl_code', {
		is: (gl_code) => {
			if (!(gl_code === undefined)) {
				return gl_code
			}
		},
		then: () => Yup.string().required('Debit Card E-NACH ID is required')
	}),
	nupay_gl_code: Yup.string().when('nupay_bank_id', {
		is: (nupay_bank_id) => {
			if (!(nupay_bank_id === undefined)) {
				return nupay_bank_id
			}
		},
		then: () => Yup.string().required('Nupay Gl Code is required')
	}),
	nupay_debit_flag: Yup.string().when('nupay_bank_id', {
		is: (nupay_bank_id) => {
			if (!(nupay_bank_id === undefined)) {
				return nupay_bank_id
			}
		},
		then: () => Yup.string().required('Debit Card Flag is required')
	}),
	nupay_netbanking_flag: Yup.string().when('nupay_bank_id', {
		is: (nupay_bank_id) => {
			if (!(nupay_bank_id === undefined)) {
				return nupay_bank_id
			}
		},
		then: () => Yup.string().required('Net Banking Flag is required')
	}),
	bancs_mirror_account: Yup.string().required(YupMessages?.BancsMirrorRequired),
	company_code: Yup.string().required("Company Code is required"),

});

const Createcollection = () => {
	const [pageNumber, setPageNumber] = useState(0);

	const navigate = useNavigate();

	const { state } = useLocation();

	const formType = state?.type || FOMRS.ADD;

	const headingText = formType === FOMRS.ADD ? "Create Collection Sponsor Bank" : "Update Collection Sponsor Bank";


	const [formData, setFormData] = useState({
		bank_code: '',
		bank_name: '',
		mandate_type: '',
		company_code: '',
		bank_identifier: '',
		f1_picklist_bank_name: '',
		sponsor_bank_id: '',
		login_id: '',
		service_provider_name: '',
		service_provider_utility_code: '',
		partner_entity_email: '',
		bank_id: '',
		amount_to_pay: '',
		merchant_id: '',
		token_id: '',
		debit_card_emandate_id: '',
		item_id: '',
		nupay_bank_id: '',
		isActive: '',
		nupay_gl_code: '',
		gl_code: '',
		bancs_mirror_account: '',
		nupay_debit_flag: '',
		nupay_netbanking_flag: '',
		upi_flag : '',
		aadhar_bank_code : ''
	});

	const [loading, setLoading] = useState(false);

	const [glcode, setGlcode] = useState(false);

	const [mandatetype, setMandatetype] = useState([
		{ key: 'Please select a Value', value: '' },
	]);

	const [company, setCompany] = useState([
		{ key: 'Please select a Value', value: '' },
	]);

	const [mandate, setMandate] = useState('');
	const [nupay, setNupay] = useState(false);

	const MandateType = () => {
		let paramObj = {
			tablename: tableNamePaymentConstants?.MandateType,
		};
		apiCall
			.post(apiConstants?.findData, paramObj)
			.then(async (res) => {
				let result = res?.data?.response;
				result = result.map((item) => {
					let newItem = {
						key: item.mandate_name,
						value: item.mandate_type,
					};

					return newItem;
				});

				setMandatetype([{ key: 'Please select a Value', value: '' }, ...result]);
			})
			.catch((er) => {
			});
	};

	const Company = () => {
		let paramObj = {
			tablename: tableNamePaymentConstants?.Company,
		};
		apiCall
			.post(apiConstants?.findData, paramObj)
			.then(async (res) => {
				let result = res?.data?.response;
				result = result.map((item) => {
					let newItem = {
						key: item.company_name,
						value: item.company_code,
					};

					return newItem;
				});

				setCompany([{ key: 'Please select a Value', value: '' }, ...result]);
			})
			.catch((er) => {
			});
	};

	const handleInputChange = (value) => {
		if (value.length > 0) {
			setNupay(true)
		} else {
			setNupay(false);
		}
	};

	const handleGLCode = (value) => {
		if (value?.length > 0) {
			setGlcode(true)
		} else {
			setGlcode(false);
		}
	};

	const FormSubmit = (value) => {
		let query = {
			body: {

				bank_code: value.bank_code,
				bank_name: value.bank_name,
				mandate_type: value.mandate_type,
				company_code: value.company_code ? value.company_code : null,
				bank_identifier: value.bank_identifier ? value.bank_identifier : null,
				f1_picklist_bank_name: value.f1_picklist_bank_name ? value.f1_picklist_bank_name : null,
				sponsor_bank_id: value.sponsor_bank_id ? value.sponsor_bank_id : null,
				login_id: value.login_id ? value.login_id : null,
				service_provider_name: value.service_provider_name ? value.service_provider_name : null,
				service_provider_utility_code: value.service_provider_utility_code ? value.service_provider_utility_code : null,
				partner_entity_email: value.partner_entity_email ? value.partner_entity_email : null,
				bank_id: value.bank_id ? value.bank_id : null,
				amount_to_pay: value.amount_to_pay ? value.amount_to_pay : null,
				merchant_id: value.merchant_id ? value.merchant_id : null,
				token_id: value.token_id ? value.token_id : null,
				debit_card_emandate_id: value.debit_card_emandate_id ? value.debit_card_emandate_id : null,
				item_id: value.item_id ? value.item_id : null,
				nupay_bank_id: value.nupay_bank_id ? value.nupay_bank_id : null,
				isActive: value.isActive,
				nupay_gl_code: value.nupay_gl_code ? value.nupay_gl_code : null,
				gl_code: value.gl_code ? value.gl_code : null,
				bancs_mirror_account: value.bancs_mirror_account,
				nupay_debit_flag: value.nupay_debit_flag ? value.nupay_debit_flag : null,
				nupay_netbanking_flag: value.nupay_netbanking_flag ? value.nupay_netbanking_flag : null,
				upi_flag : value.upi_flag ? value.upi_flag : '',
				aadhar_bank_code : value.aadhar_bank_code ? value.aadhar_bank_code : null,
			},
			tablename: tableNamePaymentConstants?.SponsorBank,
		};
		if (formType === FOMRS.EDIT) {
			query.whereQuery = {
				id: state?.data?.id
			}
			apiCall.post(apiConstants?.updateUser, query).then(async (res) => {
				toast.success(successMsg.UpdatedSuccessfully)
				navigate(ROUTE_PATH.COLLECTION_SPONSOR_BANK);
			}).catch(error => { toast.error(error.message) });

		}
		if (formType === FOMRS.ADD) {

			apiCall.post(apiConstants?.createUser, query).then(async (res) => {
				toast.success(successMsg.CreatedSuccessfully)
				navigate(ROUTE_PATH.COLLECTION_SPONSOR_BANK);
			}).catch(error => { toast.error(error.message) });
		}
	};


	useEffect(() => {
		if (formType === FOMRS.EDIT) {
			setMandate(state?.user_type?.mandate_type)
			setFormData({
				bank_code: state?.user_type?.bank_code,
				bank_name: state?.user_type?.bank_name,
				mandate_type: state?.user_type?.mandate_type,
				company_code: state?.user_type?.company_code,
				bank_identifier: state?.user_type?.bank_identifier,
				f1_picklist_bank_name: state?.user_type?.f1_picklist_bank_name,
				sponsor_bank_id: state?.user_type?.sponsor_bank_id,
				login_id: state?.user_type?.login_id,
				service_provider_name: state?.user_type?.service_provider_name,
				service_provider_utility_code: state?.user_type?.service_provider_utility_code,
				partner_entity_email: state?.user_type?.partner_entity_email,
				bank_id: state?.user_type?.bank_id,
				amount_to_pay: +state?.user_type?.amount_to_pay,
				merchant_id: state?.user_type?.merchant_id,
				token_id: state?.user_type?.token_id,
				debit_card_emandate_id: state?.user_type?.debit_card_emandate_id,
				item_id: state?.user_type?.item_id,
				nupay_bank_id: state?.user_type?.nupay_bank_id,
				isActive: state?.user_type?.isActive,
				nupay_gl_code: state?.user_type?.nupay_gl_code,
				gl_code: state?.user_type?.gl_code,
				bancs_mirror_account: state?.user_type?.bancs_mirror_account,
				nupay_debit_flag: state?.user_type?.nupay_debit_flag === true ? 1 : 0,
				nupay_netbanking_flag: state?.user_type?.nupay_netbanking_flag === true ? 1 : 0,
				upi_flag :  state?.user_type?.upi_flag  === true ? 1 : 0,
				isActive : state?.user_type?.isActive === true ? 1 : 0,
				aadhar_bank_code : state?.user_type?.aadhar_bank_code
			});

		}
		MandateType();
		Company();
	}, [loading]);

	return (

		<Formik
			initialValues={{
				bank_code: formData?.bank_code,
				bank_name: formData?.bank_name,
				mandate_type: formData?.mandate_type,
				company_code: formData?.company_code,
				bank_identifier: formData?.bank_identifier,
				f1_picklist_bank_name: formData?.f1_picklist_bank_name,
				sponsor_bank_id: formData?.sponsor_bank_id,
				login_id: formData?.login_id,
				service_provider_name: formData?.service_provider_name,
				service_provider_utility_code: formData?.service_provider_utility_code,
				partner_entity_email: formData?.partner_entity_email,
				bank_id: formData?.bank_id,
				amount_to_pay: formData?.amount_to_pay,
				merchant_id: formData?.merchant_id,
				token_id: formData?.token_id,
				debit_card_emandate_id: formData?.debit_card_emandate_id,
				item_id: formData?.item_id,
				nupay_bank_id: formData?.nupay_bank_id,
				isActive: formData?.isActive,
				nupay_gl_code: formData?.nupay_gl_code,
				gl_code: formData?.gl_code,
				bancs_mirror_account: formData?.bancs_mirror_account,
				nupay_debit_flag: formData?.nupay_debit_flag,
				nupay_netbanking_flag: formData?.nupay_netbanking_flag,
				upi_flag : formData?.upi_flag,
				aadhar_bank_code :  formData?.aadhar_bank_code
			}}
			validationSchema={validationSchema}
			enableReinitialize={true}
			onSubmit={(values) => {
				FormSubmit(values);
			}}
		>
			{
				({ values, setFieldValue }) => (
					<>
						<div id="createcollectionsponsor">
							<div className="col blueFonts large_f"
								onClick={() => navigate(ROUTE_PATH.COLLECTION_SPONSOR_BANK)}
							>
								{`Collection Sponsor Bank >> `}
								<span className="blackFonts">{headingText}</span>
							</div>
							<div className="main-div">
								<Form className=".labler-wrapper">
									<div id="RegularForm">
										<div className="createroletext">
											<p id="pText">{headingText}</p>
										</div>

										<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<div>
													<FormLabel
														value="Sponsor Bank Code"
														className="required"
													/>
												</div>
												<Field
													type="text"
													className="InputField"
													name="bank_code"
													id="bank_code"
													placeholder="Sponsor Bank"
													disabled={formType === FOMRS.EDIT ? true : false}
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="bank_code" />
												</div>
											</div>
										</div>

										<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel value="Name" className="required" />
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="bank_name"
													id="bank_name"
													placeholder="Name"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="bank_name" />
												</div>
											</div>
										</div>

										<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
												<div className="formState">
													<FormLabel value="Mandate Type" className="required" />
													<FormikSelect
														label="Please select a value"
														name="mandate_type"
														placeholder="Mandate Type"
														id='mandate_type'
														options={mandatetype}
														onClick={(values) => {
															setMandate(values.target.value)
														}}
													/>
												</div>
												<div className="errorMessage">
													<ErrorMessage name="mandate_type" />
												</div>
											</div>
										</div>

										{['LEG', 'EMANDATE', 'ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH', 'AD'].includes(mandate) && <div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="F1 Picklist Name"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="f1_picklist_bank_name"
													id="f1_picklist_bank_name"
													placeholder="F1 Picklist Name"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="f1_picklist_bank_name" />
												</div>
											</div>
										</div>}

										<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel value="GL Code" />
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="gl_code"
													id="gl_code"
													placeholder="GL Code"
													handleChange={handleGLCode(
														values?.gl_code
													)}
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="gl_code" />
												</div>
											</div>
										</div>

										{glcode && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Debit Card E-NACH ID"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="debit_card_emandate_id"
													id="debit_card_emandate_id"
													placeholder="Debit Card E-NACH ID"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="debit_card_emandate_id" />
												</div>
											</div>
										</div>
										)}
										{glcode && ['ENACH', 'EMANDATE', 'ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH', 'LEG', 'NACH', 'AD'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="EMANDATE/ENACH Netbanking Code"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="bank_id"
													id="bank_id"
													placeholder="EMANDATE/ENACH Netbanking Code"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="bank_id" />
												</div>
											</div>
										</div>
										)}
											{glcode && ['ENACH', 'EMANDATE', 'ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH', 'LEG', 'NACH', 'AD'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="TPSL Aadhar Bank Code"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="aadhar_bank_code"
													id="aadhar_bank_code"
													placeholder="TPSL Aadhar Bank Code"
												></Field>
											</div>
										</div>
										)}
										<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Bancs Mirror Account"
													className="required"
												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="bancs_mirror_account"
													id="bancs_mirror_account"
													placeholder="Bancs Mirror Account"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="bancs_mirror_account" />
												</div>
											</div>
										</div>

										<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
												<div className="formState">
													<FormLabel value="Company Code" />
													<FormikSelect
														label="Please select a value"
														name="company_code"
														id='company_code'
														options={company}
													/>
												</div>
												<div className="errorMessage">
													<ErrorMessage name="company_code" />
												</div>
											</div>
										</div>
										{['ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Sponsor Bank ID"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="sponsor_bank_id"
													id="sponsor_bank_id"
													placeholder="Sponsor Bank ID"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="sponsor_bank_id" />
												</div>
											</div>
										</div>
										)}
										{['ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Login ID"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="login_id"
													id="login_id"
													placeholder="Login ID"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="login_id" />
												</div>
											</div>
										</div>
										)}
										{['ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Service Provider Name"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="service_provider_name"
													id="service_provider_name"
													placeholder="Service Provider Name"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="service_provider_name" />
												</div>
											</div>
										</div>
										)}
										{['ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Service Provider Utility Code"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="service_provider_utility_code"
													id="service_provider_utility_code"
													placeholder="Service Provider Utility Code"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="service_provider_utility_code" />
												</div>
											</div>
										</div>
										)}
										{['ACNTVALID', 'EADDIRTECT', 'EADNPCI', 'EADTECH'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Partner Entity E-mail"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="partner_entity_email"
													id="partner_entity_email"
													placeholder="Partner Entity E-mail"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="partner_entity_email" />
												</div>
											</div>
										</div>
										)}
											{['ENACH'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
											<div className="formState">
													<FormLabel value="UPI Flag" />
													<FormikSelect
														label="Please select a value"
														name="upi_flag"
														options={isActiveDropdowns}
													/>
												</div>
											</div>
										</div>
										)}
										{['ENACH'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Nupay Bank ID"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="nupay_bank_id"
													id="nupay_bank_id"
													handleChange={handleInputChange(
														values?.nupay_bank_id
													)}
													placeholder="Nupay ID"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="nupay_bank_id" />
												</div>
											</div>
										</div>
										)}

										{nupay && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Nupay Gl Code"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="nupay_gl_code"
													id="nupay_gl_code"
													placeholder="Nupay Gl Code"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="nupay_gl_code" />
												</div>
											</div>
										</div>
										)}
										{nupay && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
												<div className="formState">
													<FormLabel value="Debit Card Flag" />
													<FormikSelect
														label="Please select a value"
														name="nupay_debit_flag"
														id='nupay_debit_flag'
														options={isActiveDropdowns}

													/>
												</div>
												<div className="errorMessage">
													<ErrorMessage name="nupay_debit_flag" />
												</div>
											</div>
										</div>

										)}
										{nupay && (
											< div className="row paddingTop ml-4 mr-4">
												<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
													<div className="formState">
														<FormLabel value="Net Banking Flag" />
														<FormikSelect
															label="Please select a value"
															name="nupay_netbanking_flag"
															id='nupay_netbanking_flag'
															options={isActiveDropdowns}

														/>
													</div>
													<div className="errorMessage">
														<ErrorMessage name="nupay_netbanking_flag" />
													</div>
												</div>
											</div>
										)}

										{['ENACH', 'EMANDATE'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Merchant ID"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="merchant_id"
													id="merchant_id"
													placeholder="Merchant ID"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="merchant_id" />
												</div>
											</div>
										</div>
										)}
										{mandate == 'EMANDATE' && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Token ID"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="token_id"
													id="token_id"
													placeholder="Token ID"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="token_id" />
												</div>
											</div>
										</div>)}
										{['ENACH', 'EMANDATE'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Item ID"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="item_id"
													id="item_id"
													placeholder="Item ID"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="item_id" />
												</div>
											</div>
										</div>)}

										{['ENACH', 'EMANDATE'].includes(mandate) && (<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn">
												<FormLabel
													value="Amount To Pay"

												/>
												{/* <Input /> */}
												<Field
													type="text"
													className="InputField"
													name="amount_to_pay"
													id="amount_to_pay"
													placeholder="Amount To Pay"
												></Field>
												<div className="errorMessage">
													<ErrorMessage name="amount_to_pay" />
												</div>
											</div>
										</div>)}
										<div className="row paddingTop ml-4 mr-4">
											<div className="col-md-12 col-lg-12 medium_f_bold directionColumn ">
												<div className="formState">
													<FormLabel value="Is Active" />
													<FormikSelect
														label="Please select a value"
														name="isActive"
														options={isActiveDropdowns}
													/>
												</div>
												<div className="errorMessage">
													<ErrorMessage name="isActive" />
												</div>
											</div>
										</div>

										<div className=" d-flex py-4">
											<div className="cancel_btn">
												<Button
													role="secondary"
													value="Cancel"
													type="button"
													onClick={() =>
														navigate(ROUTE_PATH.COLLECTION_SPONSOR_BANK)
													}
												/>
											</div>

											<div className="submitButton">
												<Button
													role="primary"
													value="Submit"
													type="submit"
													onClick={() => { }}
												/>
											</div>
										</div>
									</div>
								</Form>
							</div>
						</div>
					</>
				)
			}
		</Formik >
	);
};

export default Createcollection;
