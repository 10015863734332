import React, { useEffect, useState } from "react";

import moment from "moment";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import apiCall from "../../../utils/apiFunction/apiCall";
import downloadIcon from '../../../Images/download.svg'
import CustomTable from "../../../component/customtable/CustomTable";
import SystemXLoader from "../../../component/common/Loader/SystemXLoader";
import DynamicForm from "../../../component/common/DynamicForm/dynamicForm";
import { commonVariables, FILE_FORMAT, QUERY_PARAM, S3FILEPATH } from '../../../constants/Common/commonConstants';
import { apiConstants, errMessage, reportConstants, reportStatusAttributes, tableNamePaymentConstants, toastIds } from "../../../constants/Common/apiConstants";

import "./enachReport.scss";
import "../../../styles/layout/common.scss";
import { OneWeekLaterDate } from "../../../utils/utils";
import { enachTable, setEnachTable } from "../../../redux/reducer/accountValidationReducer";

const ValidationReport = () => {
  const [listData, setListData] = useState([]);
  const [apiLoading, setApiLoading] = useState(false);
  const [formValues, setFormValues] = useState();
  const [loading, setLoading] = useState(false);
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [type, setType] = useState('')
  const [selectOptions, setSelectOptions] = useState([
    {
      value: "single",
      label: "Single",
    },
    { value: "bulk", label: "Bulk" },
    { value: "pennyDropDump", label: "Penny Drop Dump" },
  ]);
  const loginState = useSelector((state) => state.login);
  const AccountValidationTableDetails = useSelector((state) => {
    return state?.AccountValidation?.enachTable;
  });
  const dispatch = useDispatch();

  const fileformat = async (reportType) => {
    let reportName;
    let extension;

    switch (reportType) {
      case QUERY_PARAM.Single:
        reportName = QUERY_PARAM.SINGLE_VALIDATION_REPORT;
        extension = FILE_FORMAT.csv;
        break;
      case QUERY_PARAM.Bulk:
        reportName = QUERY_PARAM.BULK_VALIDATION_REPORT;
        extension = FILE_FORMAT.csv;
        break;
      case QUERY_PARAM.pennyDropDump:
        reportName = QUERY_PARAM.PENNY_DROP_VALIDATION_REPORT;
        extension = FILE_FORMAT.csv;
        break;
      default:
        break;
    }
    try {
      let response = { reportName: reportName, extension: extension };
      return response;
    } catch (ex) {
      console.log('error', ex);
    }
  }

  const onDownload = async (data) => {
    if (data.length) {
      let tempData = [...data];
      tempData = Object.assign({}, ...tempData);
      let userData = loginState?.decodedData;
      if (tempData["To Date"] >= tempData["From Date"]) {

        const report_type = tempData["Select Type"] || "";

        const reportData = await fileformat(report_type)
        const company_code = tempData["Company Code"] || "";
        const FromDate = tempData["From Date"]
          ? moment(tempData["From Date"]).format("DD-MM-YYYY")
          : "";
        const ToDate = tempData["To Date"]
          ? moment(tempData["To Date"]).format("DD-MM-YYYY")
          : "";
        const repayment_mode = (tempData["Repayment Mode"] === '1' ? 'NACH' : tempData["Repayment Mode"] === '2' ? "AD" : tempData["Repayment Mode"] === "LEG" ? "LEG" : "ALL") || "";
        setType(tempData['Select Type']);
        let apiBody = {
          module_type: 'Account_Validation',
          sub_module_type: 'Account_Validation_Reports',
          created_by: userData?.user_name || "",
          status: QUERY_PARAM.PROCESSING,
          date_time: moment(new Date()).format('YYYY-MM-DD hh:mm:ss A'),
          from_date: FromDate,
          to_date: ToDate,
          expires_at: OneWeekLaterDate(),
          metadata: {
            startDate: FromDate,
            endDate: ToDate,
            reports: tempData['Select Type'],
            reportName: 'Account_Validation_Reports'
          }
        };
        // setApiLoading(true);
        setApiLoading(true);
        apiCall
          .post(apiConstants?.createUser, {
            body: apiBody,
            tablename: "reportStatus",
          })
          .then(async (res) => {
            if (res?.data?.status === 200) {
              if (res?.data?.response?.data?.id) {
                onUpdate(res.data.response.data, tempData['Select Type'], apiBody, reportData, repayment_mode, company_code, tempData['Select Type']);
              } else {
                toast.error(
                  res?.data?.message || errMessage?.somethingWentWrong,
                  {
                    toastId: toastIds?.FileUploaderr,
                  }
                );
              }
            } else {
              toast.error(
                res?.data?.message || errMessage?.somethingWentWrong,
                {
                  toastId: toastIds?.FileUploaderr,
                }
              );
            }
          })
          .catch((er) => {
            // setApiLoading(false);
            let resp = er?.message || "";
            toast.error(resp, { toastId: toastIds?.FileUploaderr });
          })
          .finally(() => {
            setApiLoading(false);
          });

      } else {
        toast.error(errMessage?.ToDateMustBeGreaterThanFromDate, {
          toastId: "saveMandateInfo",
        });
      }
    }
  }


  const onUpdate = async (data, type, apiBody, reportData, repayment_mode, company_code, selectedReport) => {
    setApiLoading(true);
    const key = `${loginState?.year}/${loginState?.month}/${loginState?.day}/${S3FILEPATH?.PAYMENT?.PAYMENT}/${S3FILEPATH?.COMMON?.DOWNLOAD}/${S3FILEPATH.COMMON.ACCOUNT_VALIDATION_REPORTS}/${type}/${data?.id}`
    let apiBodyUpdate = {
      metadata: {
        s3_output_path: key,
        report_name: reportData?.reportName,
        batch_id: parseInt(data?.id),
        start_date: `${apiBody?.from_date} 00:00:00`,
        end_date: `${apiBody?.to_date} 23:59:59`,
        file_format: reportData?.extension,
        mandate_type: repayment_mode,
      }
    };
    apiCall
      .post(apiConstants?.updateUser, {
        body: apiBodyUpdate,
        tablename: "reportStatus",
        whereQuery: {
          id: data.id || "",
        },
      })
      .then(async (res) => {
        if (res?.data?.status === 200) {
          if (res.data.response[0] === 1) {
            setFormValues([
              {
                type: "dateinput",
                required: true,
                value: "",
                label: "From Date",
                placeholder: "Select Date",
                width: 6,
              },
              {
                type: "dateinput",
                required: true,
                value: "",
                label: "To Date",
                placeholder: "Select Date",
                width: 6,
              },
              {
                type: "dropdown",
                required: true,
                value: "",
                label: "Select Type",
                placeholder: "Select Type",
                width: 6,
                options: selectOptions,
              },
            ]);
            reportCreationApiCall(apiBodyUpdate?.metadata);
          } else {
            toast.error(
              res?.data?.message || errMessage?.somethingWentWrong,
              {
                toastId: toastIds?.FileUploaderr,
              }
            );
          }
        } else {
          toast.error(res?.data?.message || errMessage?.somethingWentWrong, {
            toastId: toastIds?.FileUploaderr,
          });
        }
      })
      .catch((er) => {
        let resp = er?.message || "";
        toast.error(resp, { toastId: toastIds?.FileUploaderr });
      })
      .finally(() => {
        setApiLoading(false);
      });
  };


  const reportCreationApiCall = (data) => {
    setApiLoading(true);
    let userData = loginState?.decodedData;
    let paramObj = {
      "report_name": data?.report_name,
      "s3_output_path": data?.s3_output_path,
      "batch_id": data?.batch_id,
      "start_date": data?.start_date,
      "end_date": data?.end_date,
      "file_format": data?.file_format,
      "company_code": null,
      "mandate_type": data?.mandate_type,

    };
    apiCall
      .post(reportConstants?.account_validation_report_creation, paramObj)
      .then(async (res) => {
        if (res && res?.data?.status === 200) {
          let result = res?.data;
          toast.success(result.message, { toastId: toastIds.FileDownloadSucess })
          tableAPI()
        } else {
          toast.error(res?.data?.message || errMessage?.noDataFound, {
            toastId: errMessage?.noDataFound,
          });
        }
      })
      .catch((er) => {
        toast.error(er?.res?.data?.message || errMessage?.somethingWentWrong, {
          toastId: errMessage?.noDataFound,
        });
      })
      .finally(() => {
        setApiLoading(false);
      });
  };


  const fetchBlockFile = (key) => {
    apiCall
      .get(`${apiConstants?.downloadPresignedUrl}?key=${key}`)
      .then((response) => {
        if (response.status === 200) {
          const link = document.createElement("a");
          link.href = response?.data;
          link.click();
          toast.success(commonVariables?.FileDownloadSuccess, {
            toastId: toastIds?.FileDownloadSucess,
          });
        }
      })
      .catch((error) => {
        return toast.error(errMessage?.InternalServerError, {
          toastId: toastIds?.InternalServerError,
        });
      });
  };

  const downloadbtn = (data, disabledFlag) => {
    return (
      <div>
        <button
          disabled={!data || disabledFlag}
          onClick={() => fetchBlockFile(data)}
          className="downloadbtn pt-1 pe-2 pe-2 d-flex align-items-center justify-content-center"
        >
          <img src={downloadIcon} alt="download" />
          Download
        </button>
      </div>
    );
  };

  const tableAPI = () => {
    setAutoRefresh(true)
    apiCall
      .post(apiConstants?.fetchAllData, {
        tablename: tableNamePaymentConstants?.reportStatus,
        whereQuery: {
          created_by: loginState?.decodedData?.user_name || "",
          module_type: commonVariables?.Account_Validation,
        },
        attributes: reportStatusAttributes,
        order: [["id", "desc"]],
      })
      .then(async (res) => {
        if (res?.data?.status == 200) {
          let result = res?.data?.response;
          dispatch(setEnachTable(JSON.stringify(result)));
          result = result.map((item, index) => {
            let newItem = {
              idData: index + 1,
              date: `${item?.metadata?.start_date?.split(' ')[0] || ""
                } to ${item?.metadata?.end_date?.split(' ')[0] || ""
                }`,
              moduletype: item?.module_type || "",
              submoduletype: item?.sub_module_type || "",
              rpname: item?.metadata?.report_name || "-",
              createdby: item?.created_by || "-",
              status: item?.status || "",
              action: downloadbtn(item?.s3_path, item?.status === "No data found" || item?.status === "Processing"),
            };
            return newItem;
          });
          let slice20 = result.slice(0, 20)
          if (slice20?.length > 0) {
            setListData([...slice20]);
          }
        } else {
          setListData([]);
          toast.error(res?.data?.message, {
            toastId: toastIds?.DataError,
          });
        }
      })
      .catch((er) => {
        setListData([]);
        toast.error(er?.message, { toastId: toastIds?.DataError });
      }).finally(() => {
        setAutoRefresh(false)
      })
  };


  useEffect(() => {
    if (AccountValidationTableDetails) {
      let reduxValues = JSON.parse(AccountValidationTableDetails);
      reduxValues = reduxValues.map((item, index) => {
        let newItem = {
          idData: index + 1,
          date: `${item?.metadata?.start_date?.split(' ')[0] || ""
            } to ${item?.metadata?.end_date?.split(' ')[0] || ""
            }`,
          moduletype: item?.module_type || "",
          submoduletype: item?.sub_module_type || "",
          rpname: item?.metadata?.report_name || "-",
          createdby: item?.created_by || "-",
          status: item?.status || "",
          action: downloadbtn(item?.s3_path, item?.status === "No data found" || item?.status === "Processing"),
        };
        return newItem;
      });
      let slice20 = reduxValues.slice(0, 20)
      if (slice20?.length > 0) {
        setListData([...slice20]);
      }
    }
  }, [AccountValidationTableDetails]);
  useEffect(() => {
    setFormValues([
      {
        type: "dateinput",
        required: true,
        value: "",
        label: "From Date",
        placeholder: "Select Date",
        width: 6,
      },
      {
        type: "dateinput",
        required: true,
        value: "",
        label: "To Date",
        placeholder: "Select Date",
        width: 6,
      },
      {
        type: "dropdown",
        required: true,
        value: "",
        label: "Select Type",
        placeholder: "Select Type",
        width: 6,
        options: selectOptions,
      },
    ]);
  }, [selectOptions]);

  return (
    <>
      {loading && (
        <div className="loaderDiv">
          <SystemXLoader />
        </div>
      )}
      <div className="mt-4 pt-1">
        <p className="report-container_ReportTxt ps-4 ">
          <b>Reports</b>
        </p>
        <div className="report-container p-4 shadow-sm border rounded-3">
          <DynamicForm
            formValues={formValues}
            isDownload
            onSubmit={onDownload}
          />
        </div>
      </div>
      <div className="mx-4 pb-4">
        {listData.length > 0 && (
          <>
            <div className="pt-2 toggle-wrapper">
              <button
                onClick={() => tableAPI()}
                disabled={autoRefresh}
                className="customRefreshButton mt-1"
              >
                Refresh
              </button>
            </div>
            <CustomTable
              cols={[
                {
                  id: "idData",
                  title: "ID",
                  classStyle: {},
                },
                {
                  id: "date",
                  title: "Date Range",
                  classStyle: {},
                },
                {
                  id: "rpname",
                  title: "Report Name",
                  classStyle: {},
                },
                {
                  id: "createdby",
                  title: "Created By",
                  classStyle: {},
                },
                {
                  id: "status",
                  title: "Status",
                  classStyle: {},
                },
                {
                  id: "action",
                  title: "Action",
                  classStyle: {},
                },
              ]}
              data={listData}
              loading={apiLoading}
            />
          </>
        )}
      </div>

    </>
  );
};
export default ValidationReport;
