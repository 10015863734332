import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const dateTimeReducer = createSlice({
  name: "dateTime",
  initialState,
  reducers: {
    setFromDateTime: (state, action) => {
      state.fromDateTime = action.payload;
    },
    setToDateTime: (state, action) => {
      state.toDateTime = action.payload;
    },
  },
});
export const { setFromDateTime, setToDateTime } = dateTimeReducer.actions;
export const selectArray = (state) => state.dateTime.enachTable;
export const dateTime = dateTimeReducer.reducer;

// const initialState = {
//     startDate: '',
//     endDate: '',
//   };

//   const datetimeReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case 'SET_START_DATE':
//         return { ...state, startDate: action.payload };
//       case 'SET_END_DATE':
//         return { ...state, endDate: action.payload };
//       default:
//         return state;
//     }
//   };

//   export default datetimeReducer;
