import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  webSocket: {},
};

const webSocketRudece = createSlice({
  name: "webSocket",
  initialState,
  reducers: {
    webSocketData: (state, action) => {
      console.log("--------------> webSocketData",state, action);
      state.webSocket=action.payload;
      console.log("---------------> state websocket",state.webSocket);
    },
  },
});
export const  {webSocketData} = webSocketRudece.actions;
export const webSocketReducer= webSocketRudece.reducer;
