import React from "react"

import {Modal, Button} from 'react-bootstrap';  


function ModalComponent(props) {


    return (
        <div className="modalContainer">
            <Modal className="modal-dialog modal-lg" show={props.show} onHide={props.onHide}>
                                    <Modal.Header closeButton>
                                      <Modal.Title><b>{props.title}</b></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="modalBody">
                                    
                                        {props.modalBody}
                                    
                                    </Modal.Body>



                                    <Modal.Footer className="modalFooter">

                                      <Button variant="secondary" onClick={props.modalClose}>{props.btnTitle}</Button>
                                    </Modal.Footer>

                                  </Modal>
        </div>

    )
}

export default ModalComponent

